.copy-list
	background-color: $color-grey-light
	display: block
	position: relative
	width: 100%
	&_list
		counter-reset: item
		display: block
		list-style: none
		padding-top: 10rem
		position: relative
	&_entry
		counter-increment: item
		.grey-content-box
			padding: 6rem 0
		&:first-child
			.grey-content-box
				padding-top: 0
		& + &
			.grey-content-box
				padding-top: 0
		&_headline
			display: block
			position: relative
		&_text
			line-height: 1.625em
			+richtext-liststyle
	ol
		.copy-list_entry
			&_headline
				display: block
				padding-left: .3em
				position: relative
				.headline
					align-items: center
					display: flex
					flex-direction: row
					flex-wrap: nowrap
					&::before
						content: counter(item)'.'
						display: block
						font-size: 5rem
						+getComponent('font-bold-obl')
						margin-right: .4em
						position: relative
	ul
		.copy-list_entry
			&_headline
				padding-left: 4rem
				.icon
					display: block
					height: auto
					left: 0
					position: absolute
					top: -.3rem
					width: 3rem
				.headline
					display: block
					position: relative

	@media screen and (max-width: $mobilebp)
		&_header
			flex-wrap: wrap
			&_img
				margin: 0
				order: 2
				width: 100%
			&_text
				margin: 0
				order: 1
				padding: 3rem 1.7rem 4rem 1.7rem
				width: 100%
		&_list
			.grey-content-box
				&_left,
				&_right
					padding: 0
		ol,
		ul
			.copy-list_entry
				&_headline
					margin-bottom: 2rem