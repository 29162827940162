.tag
	color: inherit
	display: inline-block
	font-size: 1.6rem
	margin: 0 .3em
	position: relative
	&_text-before,
	&_text-after
		display: inline-block
		position: relative
	&_text-before
		margin-right: .3em
	&_text-after
		margin-left: .3em
	&_tag
		cursor: pointer
		color: inherit
		display: inline-block
		+getComponent('font-bold')
		+noselect()
		padding-right: 3rem
		position: relative
		+transition(color .3s ease)
		span
			display: inline-block
			position: relative
		&_remove
			background-color: transparent
			border: .3rem solid $color-red
			+borderradius(50%)
			display: block
			height: 2.2rem
			position: absolute
			right: 0
			top: calc(50% - .1em)
			+transform(translateY(-50%))
			+transition(transform .3s ease)
			width: 2.2rem
			&::before,
			&::after
				background-color: $color-red
				content: ''
				display: block
				height: .2rem
				left: 50%
				position: absolute
				top: 50%
				+transform(translate(-50%,-50%) rotate(45deg))
				+transition(background-color .3s ease)
				width: 1rem
			&::after
				+transform(translate(-50%,-50%) rotate(-45deg))
	@media (hover:hover)
		&_tag
			&:hover
				.tag_tag_remove
					background-color: $color-red
					&::before,
					&::after
						background-color: $color-white