.search-bar-hero
	background-color: $color-grey
	+backgroundcover
	background-position: top center
	display: block
	margin: 0 2rem
	min-height: 100vh
	padding: 30vh 0 15vh 0
	position: relative
	width: calc(100% - 4rem)
	.breaker
		position: absolute
		right: -5rem
		top: 0
	.wrapper
		max-width: 99rem
	&_img-darkener
		background-color: $color-bg-darkener
		display: block
		height: 100%
		left: 0
		margin: 0
		position: absolute
		top: 0
		width: 100%
	&_opener
		color: $color-white
		width: 75%
		.headline
			+hyphens(none)
		.btn
			margin-top: 2.5rem
	&_searchbar
		display: block
		margin-top: 10vh
		min-height: calc(10vh + 1.5rem)
		position: relative
	img
		+reader-image

	@media screen and (max-width: 1200px)
		.breaker
			right: 2rem
	@media screen and (max-width: $tabletbp)
		.breaker
			right: 4rem

	@media screen and (max-width: $mobilebp)
		align-items: center
		display: flex
		flex-direction: row
		flex-wrap: wrap
		margin: 0 1.2rem
		padding: 5rem 0
		width: calc(100% - 2.4rem)
		.breaker
			left: 50%
			right: auto
			top: -2rem
			+transform(translate(-50%,-100%) rotate(10deg))
		&_opener
			text-align: center
			width: 100%
		&_searchbar
			margin-top: 15vh
			min-height: inherit