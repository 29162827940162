.page-hero
	display: flex
	flex-direction: row
	flex-wrap: nowrap
	position: relative
	width: 100%
	&_left
		display: flex
		flex-direction: row
		flex-grow: 1
		flex-wrap: wrap
		margin-left: auto
		max-width: math.div($wrapper-maxwidth,2)
		overflow: hidden
		padding-right: 4rem
		position: relative
	&_right
		display: block
		min-width: 50%
		position: relative
		width: 50%
	&_breadcrumbs
		align-self: flex-start
		display: block
		position: relative
		width: 100%
	&_details
		display: block
		padding-left: 4rem
		position: relative
		width: 100%
		&_title
			.headline
				overflow-wrap: break-word
				text-overflow: ellipsis
				width: 100%
		&_button
			display: block
			margin-top: 2rem
			position: relative
	&_img
		+backgroundcover()
		background-repeat: no-repeat
		display: block
		margin-right: 2rem
		min-height: calc(53vw - 10rem)
		position: relative
		width: calc(100% - 2rem)
		img
			display: block
			opacity: 0
			position: absolute

	@media screen and (max-width: $mobilebp)
		flex-wrap: wrap
		&_left,
		&_right
			width: 100%
		&_left
			order: 2
		&_right
			margin-top: 1rem
			order: 1
		&_details
			padding: 0 2rem
		&_img
			margin: 0 1.2rem 3rem 1.2rem
			min-height: 90vw
			width: calc(100% - 2.4rem)