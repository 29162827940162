.career-choice-test
	background-color: $color-red
	color: $color-white
	display: block
	overflow-anchor: none
	width: 100%
	.wrapper
		max-width: calc(#{$wrapper-maxwidth} + 8rem)
	.qmbg,
	.embg
		&::before
			content: '?'
			color: $color-red-dark
			display: block
			font-size: 50vw
			+getComponent('font-demi')
			left: 35%
			position: absolute
			top: 50%
			+transform(translateY(-40%) rotate(30deg))
	.embg
		&::before
			content: '!'
			left: 50%
	&_bgrd
		display: block
		left: 0
		height: 100%
		overflow: hidden
		position: absolute
		top: 0
		width: 100%
	&_start
		display: block
		padding: 13rem 0
		position: relative
		width: 100%
		.wrapper
			align-items: center
			display: flex
			flex-direction: row
			flex-wrap: nowrap
		&_headline
			color: $color-white
			margin-right: 5rem
			width: calc(60% - 5rem)
		&_box
			background-color: $color-white
			color: $color-black
			display: block
			margin-left: 5rem
			padding: 4rem
			width: calc(40% - 5rem)
			&_text
				font-size: 1.6rem
				line-height: 1.625em
			&_button
				display: block
				margin-top: 2.5rem
				position: relative
	&_questions
		display: none
		padding: 13rem 0
		position: relative
		width: 100%
		&.active
			display: block
		&_counter
			display: block
			font-size: 3rem
			+getComponent('font-demi')
			margin-bottom: 2.5rem
			position: relative
			text-align: center
			width: 100%
			&_whole
				font-size: .7em
				margin-left: .3em
		&_question
			display: none
			position: relative
			width: 100%
			&.active
				display: block
		&_control
			align-items: center
			display: flex
			flex-direction: row
			flex-wrap: wrap
			gap: 3rem
			margin-top: 5rem
			&_left
				&_back
					display: none
					flex-grow: 1
					pointer-events: none
					&.show
						display: block
						pointer-events: all
			&_right
				&_skip
					font-size: 1.8rem
					margin-left: auto
					margin-right: 2rem
					&.hide
						display: none
						pointer-events: none
					.link
						&:link,
						&:visited,
						&:active
							color: $color-white
				&_next
					display: block

	&_result
		display: none
		padding: 13rem 0
		position: relative
		width: 100%
		&_headline
			display: block
			position: relative
			text-align: center
			width: 100%
		&_matches
			display: flex
			flex-direction: row
			flex-wrap: wrap
			margin: 3.5rem -1.5rem
			width: calc(100% + 3rem)
			.job-profile-card
				margin: 1.5rem
				width: calc(33% - 3rem)
				&_content
					min-height: 100%
		&_control
			display: flex
			flex-direction: row
			flex-wrap: wrap
			gap: 2rem
			width: 100%
			&_redo
				flex-grow: 1
			&_offers
				align-self: flex-end
	&.started
		.career-choice-test
			&_start
				display: none
			&_questions
				display: block
	&.result
		.career-choice-test
			&_questions
				display: none
			&_result
				display: block

	&--answer-required
		.career-choice-test
			&_questions
				&_control
					&_right
						&_skip
							opacity: 0
							pointer-events: none

	@media screen and (max-width: $tabletbp)
		&_start
			.wrapper
				flex-wrap: wrap
			&_headline
				margin: 0
				width: 100%
			&_box
				align-self: flex-end
				margin: 5rem 0 0 auto
				width: 51%
		&_result
			&_matches
				.job-profile-card
					width: calc(50% - 3rem)

	@media screen and (max-width: $mobilebp)
		.wrapper
			max-width: inherit
		&_start
			padding: 5rem 0
			.wrapper
				display: block
			&_headline,
			&_box
				margin: 0
				width: 100%
			&_headline
				margin-bottom: 5rem
		&_questions
			padding: 5rem 0
			&_control
				margin-top: 5rem
				&_left
					&_back
						order: 2
				&_right
					&_skip
						margin-right: 0
						order: 1
						text-align: right
						width: 100%
					&_next
						margin-left: auto
						order: 3
		&_result
			padding: 5rem 0
			&_matches
				margin: 0
				width: 100%
				.job-profile-card
					margin: 1.5rem 0
					width: 100%
			&_control
				gap: 1rem
				justify-content: end
				margin-top: 5rem
				&_offers
					margin-top: 2rem