.grey-content-box
	background-color: $color-grey-light
	display: block
	padding: 10.6rem 0
	position: relative
	.wrapper
		display: flex
		flex-direction: row
		flex-wrap: nowrap
		max-width: 100rem
		position: relative
		width: 100%
	&_left,
	&_right
		display: block
		position: relative
		width: 50%
	&_left
		padding-right: 10rem
	&_right
		padding-left: 3rem
	&.right
		.grey-content-box
			&_left
				order: 2
				padding-left: 3rem
				padding-right: 0
			&_right
				order: 1
				padding-left: 0
				padding-right: 10rem

	@media screen and (max-width: $mobilebp)
		padding: 3rem 0
		.wrapper
			flex-wrap: wrap
			max-width: inherit
		&_left,
		&_right
			padding: 0 3rem
			width: 100%
		&.right
			.grey-content-box
				&_left
					order: 1
					padding: 0 3rem
				&_right
					order: 2
					padding: 0 3rem