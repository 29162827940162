.search-bar
	display: block
	margin: 0 auto
	position: relative
	input
		background-color: $color-white-a70
		color: $color-black
		display: block
		font-size: 1.6rem
		+getComponent('font-demi')
		min-height: 6.3rem
		padding: 2.3rem 0 2.1rem 6rem
		position: relative
		+transition(background-color .3s ease)
		width: 100%
	&_icon
		display: block
		left: 1.5rem
		position: absolute
		top: 50%
		+transform(translateY(-50%))
		z-index: 1
		.icon
			color: $color-red
			font-size: 3rem
	&.big
		margin-top: 6rem
		max-width: 65rem
		width: auto
		input
			display: inline-block
			font-size: 5rem
			padding: 2.1rem 0 1rem 10rem
		.icon
			font-size: 6rem
	&.flyout
		background-color: transparent
		border: .6rem solid transparent
		+boxshadow(0 0 0 0 $shadow_search-bar_flyout)
		+transition(background-color .3s ease, border-color .3s ease, box-shadow .3s ease)
		position: absolute
		width: 100%
		.search-bar_icon
			top: 1.5rem
			+transform(translateY(0))
	&_flyout
		display: flex
		flex-direction: column
		flex-wrap: nowrap
		height: 55rem
		max-height: 0
		opacity: 0
		overflow: hidden
		padding: 0 4rem
		position: relative
		+transition(max-height .3s ease, opacity .3s ease, padding .3s ease)
		::-webkit-scrollbar
			width:0
			height:0
		::-webkit-scrollbar-thumb
			background: transparent
		::-webkit-scrollbar-track
			background-color: transparent

		&_offers
			align-self: flex-start
			flex-grow: 1
			display: block
			overflow: hidden
			position: relative
			width: 100%
			&_headline
				display: block
				font-size: 1.4rem
				+getComponent('font-md')
				line-height: 1.7em
				margin: 2rem 0
				position: relative
			&_preload
				display: block
				position: relative
				&_text
					display: block
					margin-bottom: 2rem
					position: relative
					text-align: center
				&.hide
					display: none
			&_extended
				display: block
				margin: 2rem 0
				position: relative
				text-align: center
				width: 100%
				&.hide
					display: none
			&_list
				display: block
				max-height: calc(100% - 6rem)
				overflow-y: auto
				padding-right: 10.1rem
				position: relative
				width: 100%
				&-scroll
					background-color: $color-scroll-bar-bg
					display: block
					height: 100%
					position: absolute
					right: 0
					top: 0
					width: 1.1rem
					&_bar
						background-color: $color-grey
						display: block
						left: 0
						min-height: 1rem
						position: absolute
						top: 0
						+transform(translateY(0))
						width: 1.1rem
					&.disabled
						opacity: .3
						.search-bar_flyout_offers_list-scroll_bar
							display: none
		&_link
			align-self: flex-end
			display: block
			font-size: 1.8rem
			margin-top: 2.5rem
			position: relative
			text-align: right
			width: 100%
	&.flyout-focus
		background-color: $color-white
		border-color: $color-red
	&.flyout-open
		background-color: $color-white
		border-color: $color-red
		+boxshadow(0 .2rem .6rem 0 $shadow_search-bar_flyout)
		z-index: $layer_search-bar_open
		.search-bar_flyout
			max-height: 55rem
			opacity: 1
			padding: 2rem 4rem 3rem 4rem


	@media screen and (max-width: $mobilebp)
		input
			font-size: 1.5rem
			min-height: 4rem
			padding: 1.2rem 0 1rem 4rem
		.icon
			font-size: 2rem
			left: 1rem
		&.big
			margin: 3rem 3.5rem 0 3.5rem
			input
				font-size: 2.4rem
				padding: 1.1rem 0 .3rem 3.5rem
			.search-bar_icon
				left: 0
			.icon
				font-size: 2.6rem
				left: 0
		&.flyout-focus
			background-color: transparent
			border: .6rem solid transparent
			input
				background-color: $color-white
		&.flyout-open
			background-color: transparent
			border-color: transparent
			+boxshadow(none)
			.search-bar_flyout
				max-height: 0
				opacity: 0
				padding: 0
		&.flyout
			.search-bar_icon
				left: 0
				top: 1rem
