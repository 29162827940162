.card-grid
	display: block
	padding: 8rem 0
	position: relative
	.wrapper
		display: flex
		flex-direction: row
		flex-wrap: wrap
		justify-content: space-around
	&_headline
		margin-bottom: 3rem
		padding-right: 3rem
		width: 25%
		.desktop
			display: block
		.mobile
			display: none
		.headline
			max-width: 100%
			overflow-wrap: break-word
	&_cards
		display: flex
		flex-wrap: wrap
		justify-content: space-between
		width: 75%
		&::after
			content: ''
			width: calc(33% - 1.5rem)
		&_elem
			margin-bottom: 4.5rem
			margin-right: 1.5rem
			max-width: 27.8rem
			overflow: hidden
			width: calc(33% - 1.5rem)
			&:nth-child(3n)
				margin-right: 0
			&_img
				margin-bottom: 0.8rem
				padding-top: 100%
				position: relative
				+transition(transform .3s ease)
				width: 100%
				&_bg
					+backgroundcover
					background-position: center center
					background-repeat: no-repeat
					display: block
					height: 100%
					left: 50%
					position: absolute
					top: 50%
					+transform(translate(-50%,-50%) scale(1))
					+transition(transform .3s ease)
					width: 100%
			&_link
				display: block
				+noselect()
				padding: 1.3rem 3.5rem 1rem 0
				position: relative
				.headline
					max-width: 100%
				.icon
					bottom: .5rem
					display: block
					font-size: 3.2rem
					opacity: 0
					position: absolute
					right: 0
					+transition(opacity .3s ease)
			img
				left: 0
				opacity: 0
				position: absolute
				top: 0
			@media (hover:hover)
				&:hover
					.card-grid_cards_elem
						&_link
							.icon
								opacity: 1
						&_img
							&_bg
								+transform(translate(-50%,-50%) scale(1.03))
	
	@media screen and (max-width: $tabletbp)
		&_headline
			padding: 0
			text-align: left
			width: 100%
		&_cards
			width: 100%

	@media screen and (max-width: $mobilebp)
		&_headline
			.desktop
				display: none
			.mobile
				display: block
		&_cards
			&_elem
				margin-bottom: 2.5rem
				width: calc(50% - 1.5rem)
				&:nth-child(2n)
					margin-right: 0
				&:nth-child(3n)
					margin-right: 1.5rem
				&_link
					.headline
						font-size: 1.6rem
	@media screen and (max-width: $minimobilebp)
		&_cards
			&_elem
				margin: 0 auto 2.5rem auto
				width: 100%
				&:nth-child(2n),
				&:nth-child(3n)
					margin-right: auto