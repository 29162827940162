a,
button
	&.btn
		background-color: $color-grey-dark
		border: .4rem solid $color-grey-dark
		color: $color-white
		cursor: pointer
		display: inline-block
		font-size: 1.6rem
		+getComponent('font-demi')
		outline: none
		padding: 1.2rem 2.6rem 1rem 2.6rem
		position: relative
		+transition(background-color .3s ease, border-color .3s ease, border-width .3s ease, color .3s ease, padding .3s ease, transform .3s ease)
		+noselect()
		width: auto
		.btn_text
			position: relative
		.btn_icon
			color: inherit
			display: block
			height: 2rem
			position: absolute
			right: 1.5rem
			top: 50%
			+transform(translateY(-50%))
			.icon
				height: 2rem
				width: 2rem
		@media (hover:hover)
			&:hover
				color: $color-grey-dark
		&.icon-btn
			padding-right: 5rem
		&.no-text-btn
			height: 4.8rem
			padding: 0
			top: -.3em
			width: 4.8rem
			&.icon-btn
				.btn_icon
					height: 3rem
					right: 50%
					+transform(translate(50%,-50%))
					.icon
						height: 3rem
						width: 3rem
		&.primary
			background-color: $color-red
			border-color: $color-red
			@media (hover:hover)
				&:hover
					color: $color-red
		&.black
			background-color: $color-black
			border-color: $color-black
			@media (hover:hover)
				&:hover
					color: $color-black
		@media (hover:hover)
			&:hover		
				background-color: $color-white
		&:focus
			background-color: $color-white
			border-width: .3rem
			color: $color-white
			padding: 1.3rem 2.7rem 1.1rem 2.7rem
			&::before
				background-color: $color-grey-dark
				content: ''
				height: calc(100% - .6rem)
				left: .3rem
				position: absolute
				top: .3rem
				width: calc(100% - .6rem)
				z-index: 0
			&.primary
				color: $color-white
				&::before
					background-color: $color-red
			&.icon-btn
				padding-right: 5.1rem
				.btn_icon
					right: 1.6rem
			&.no-text-btn
				padding: 0
				.btn_icon
					right: 50%
		&:disabled
			background-color: $color-black-25
			border-color: $color-black-25
			pointer-events: none
			&.primary
				background-color: $color-red-light
				border-color: $color-red-light

		@media screen and (max-width: $mobilebp)
			font-size: 1.4rem
			padding: .7rem 1.8rem .5rem 1.8rem
			&.icon-btn
				padding-right: 3.5rem
				.btn_icon
					height: 1.32rem
					right: 1rem
					.icon
						height: 1.32rem
						width: 1.32rem
			&.no-text-btn
				height: 4rem
				padding: 0
				width: 4rem
				.btn_icon
					height: 1.8rem
					.icon
						width: 1.8rem
						height: 1.8rem
			&:focus
				padding: .8rem 1.9rem .6rem 1.9rem
				&.icon-btn
					padding-right: 3.6rem
					.btn_icon
						right: 1.1rem
				&.no-text-btn
					padding: 0
					.btn_icon
						right: 50%