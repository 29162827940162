// COMMON
$common_fontfolder:				'../../fonts/'



// COLORS
$color-black:					#000			
$color-white:					#FFF

$color-grey:					#7F7F7F
$color-grey-light:				#F6F6F6
$color-grey-dark:				#404040

$color-red:						#E4032E
$color-red-light:				#F6B3C0
$color-red-dark:				#B00022
$color-red-shadow:				#520010

$color-shadow_dark:				#3F3F3F

$color-black-75:				$color-grey-dark
$color-black-50:				$color-grey
$color-black-25:				#BFBFBF

$color-white-a70:				rgba($color-white,.7)
$color-white-a60:               rgba($color-white,.6)
$color-white-a30:               rgba($color-white,.3)

$color-bg-darkener:				rgba($color-black,.9)
$color-scroll-bar-bg:			rgba($color-grey,.3)


$shadow_cookie-banner:          rgba($color-black,.11)
$shadow_header-flyout:			rgba($color-shadow-dark,.38)
$shadow_search-bar_flyout:		rgba($color-red-shadow,.8)
$shadow_job-profile-card:		rgba($color-shadow-dark,.5)
$shadow_job-profile-card-cct:	rgba($color-red-shadow,.8)


// LAYERING
$layer_page-frame:				100
$layer_copyright:               200
$layer_search-bar_open:			500
$layer_header:					5000
$layer_scroll-down-hint:        5050
$layer_tooltips:				5100
$layer_cookie-banner:           8000



// MEASUREMENTS AND BREAKPOINTS
$maxwidth:						1280px
$tabletbp:						1024px
$mobilebp:						768px
$minimobilebp:					374px
$minwidth:						320px

$wrapper-maxwidth:				1200px
$header-footer-maxwidth:		1400px
