.copy-headline
	display: block
	position: relative
	.grey-content-box
		&_right
			line-height: 1.625em
	@media screen and (max-width: $mobilebp)
		.grey-content-box
			&_left
				margin-bottom: 3.5rem
			&_right
				padding-bottom: 2rem