.headline
	color: inherit
	display: block
	+hyphensauto
	position: relative
	span
		display: block
		font-size: .6em
		+getComponent('font-bk')
		line-height: 1.5em
		margin-bottom: .8rem
		position: relative

h1.headline
	font-size: 7rem
	+getComponent('font-bold-obl')
	line-height: 1.14em
	span
		font-size: .27em
		margin-bottom: 1em
		+getComponent('font-bold')

h2.headline
	font-size: 5rem
	+getComponent('font-bold-obl')
	line-height: 1.12em

h3.headline
	font-size: 3.2rem
	+getComponent('font-bold')
	line-height: 1.25em

h4.headline
	font-size: 2.5rem
	+getComponent('font-bold-obl')
	line-height: 1.28em

h5.headline
	font-size: 1.9rem
	+getComponent('font-bold')
	line-height: 1.26em

h6.headline
	font-size: 1.8rem
	+getComponent('font-demi-obl')
	line-height: 1.33em


@media screen and (max-width: $mobilebp)
	.headline
		span
			font-size: .8em
	h1.headline
		font-size: 4rem
		line-height: 1.2em
		span
			font-size: .45em
	h2.headline
		font-size: 3rem
		line-height: 1.266em
	h3.headline
		font-size: 2.5rem
		line-height: 1.32em
	h4.headline
		font-size: 2rem
		line-height: 1.4em
	h5.headline
		font-size: 1.8rem
		line-height: 1.44em
	h6.headline
		font-size: 1.6rem
		line-height: 1.625em