.breadcrumbs
	display: block
	margin-bottom: 2rem
	ul
		align-items: baseline
		display: flex
		flex-direction: row
		flex-wrap: wrap
		list-style: none
		li
			display: inline-block
			margin-bottom: 1rem
			max-width: 30em
			overflow: hidden
			text-overflow: ellipsis
			white-space: nowrap
			.icon
				color: $color-black
				font-size: 2.3rem
				margin: 0 1rem
			a
				&, &:link, &:visited, &:active
					color: $color-red
					font-size: 1.4rem
					+getComponent('font-demi')
					.icon
						color: $color-red
						font-size: 2rem
			&:first-child
				.icon
					margin-left: 0
				a
					.icon
						top: -.1em
			&:last-child
				.icon
					display: none
				a
					&, &:link, &:visited, &:active
						color: $color-black
						pointer-events: none
	@media screen and (max-width: $mobilebp)
		ul
			padding-left: 1rem
