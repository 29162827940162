.icon-bulletpoint
	color: $color-black
	display: block
	position: relative
	&_icon
		background-color: $color-red
		+borderradius(50%)
		color: $color-white
		display: block
		height: 6.4rem
		margin-bottom: 1.4rem
		position: relative
		width: 6.4rem
		svg
			display: block
			height: auto
			left: 50%
			position: absolute
			top: 50%
			+transform(translate(-50%,-50%))
			width: 60%
	&_headline
		display: block
		margin-bottom: 1rem
		position: relative
	&_text
		display: block
		font-size: 1.6rem
		+getComponent('font-reg')
		line-height: 1.4375em
		position: relative

	@media screen and (max-width: $mobilebp)
		&_icon
			height: 5rem
			width: 5rem