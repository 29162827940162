.offer-header
	background-color: $color-grey-light
	display: flex
	flex-direction: row
	flex-wrap: nowrap
	position: relative
	width: 100%
	&_left,
	&_right
		flex-grow: 1
		display: block
		padding-bottom: 6rem
		position: relative
		width: 50%
	&_left
		background-color: $color-white
	&_right
		max-width: 50%
		&_image
			background-color: $color-grey
			+backgroundcover
			background-position: top center
			margin-right: 2rem
			min-height: 100vh
			width: calc(100% - 2rem)
			img
				+reader-image
			.image-copyright
				align-items: flex-start
				bottom: auto
				right: 3rem
				top: 1rem
		&_description
			display: block
			line-height: 1.625em
			max-width: math.div($wrapper-maxwidth,2)
			padding: 6rem 2rem 0 4rem
			position: relative
			b, strong
				font-size: 1em
				+getComponent('font-md')
			.headline
				font-size: 1em
				margin: 2em auto 1em auto
				+getComponent('font-bold')
			ul, ol
				list-style: none
			ul
				li
					padding-left: 1.7em
					position: relative
					&::before
						background-color: $color-black
						+borderradius(50%)
						content: ''
						display: block
						height: .63em
						left: 0
						position: absolute
						top: .5em
						width: .65em
	&_breadcrumbs
		display: none
		margin-left: auto
		position: relative
	&_details
		align-items: center
		display: flex
		flex-direction: row
		flex-wrap: wrap
		left: 0
		min-height: 100vh
		position: sticky
		top: 0
		width: 100%
		&_content
			display: block
			margin-left: auto
			max-width: math.div($wrapper-maxwidth,2)
			overflow-x: hidden
			padding: 4rem
			text-overflow: ellipsis
			width: 100%
	&_classification
		margin-bottom: 1.5rem
	&_title
		margin-top: 2.2rem
	&_city,
	&_vacancy
		.icon
			color: $color-red
			font-size: 3rem
			margin-right: 1.5rem
	&_city
		margin-bottom: 1.5rem
		margin-top: 2rem
	&_title
		.headline
			max-width: 100%
			overflow: hidden
			text-overflow: ellipsis
	&_classification,
	&_employer,
	&_city,
	&_vacancy
		max-width: 100%
		overflow: hidden
		padding-bottom: .2em
		text-overflow: ellipsis
	&_button
		margin-top: 8rem
		text-align: left
		&_sh
			display: inline-block
			margin-left: 1.5rem
			position: relative
	&_mobile-image
		background-color: $color-grey
		+backgroundcover
		background-position: top center
		display: none
		margin: 0 1.2rem
		min-height: 55vh
		position: relative
		width: calc(100% - 2.4rem)
		img
			+reader-image


	@media screen and (max-width: $mobilebp)
		background-color: $color-white
		flex-wrap: wrap
		margin-bottom: 4rem
		&_left,
		&_right
			margin: 0 auto
			max-width: inherit
			padding: 0
			width: 100%
		&_left
			order: 1
		&_right
			margin-top: 3rem
			order: 2
			&_image
				display: none
			&_description
				background-color: $color-grey-light
				margin-top: 5rem
				padding: 6rem 1.7rem 4rem 1.7rem
		&_breadcrumbs
			display: block
			max-width: inherit
			padding: 2rem 0 1rem 0
			width: 100%
			.breadcrumbs
				.wrapper
					padding: 0 1.2rem 0 .5rem
				ul
					li
						max-width: inherit
		&_mobile-image
			display: block
		&_details
			min-height: inherit
			&_content
				padding: 0 1.7rem
		&_button
			margin-top: 2rem
			text-align: left