.sitemap
	display: block
	margin: 3rem 0
	position: relative
	width: 100%
	&_section
		display: block
		margin: 4rem 0
		position: relative
	ul
		display: block
		list-style: none
		margin: .5em 0 1em 1.5em
		position: relative
		li
			display: block
			margin: .5em 0
			position: relative
			&::before
				color: $color-black
				content: '\00ac'
				display: block
				font-size: 1.5em
				left: -.75em
				position: absolute
				top: 0
				+transform(scale(-1,-1))

	@media screen and (max-width: $mobilebp)
		margin: 1rem 0
		padding: 0 1rem