.calendar-overview
    display: block
    position: relative
    width: 100%
    &__headline
        margin-bottom: 8rem
        margin-top: -2rem
        text-align: center
    &__list
        display: flex
        flex-direction: column
        position: relative
        .calendar-entry
            margin-bottom: 3rem
            &:last-child
                margin-bottom: 0
            &.calendar-overview__entry--hidden
                display: none!important
    @media screen and (max-width: $mobilebp)
        &__headline
            margin-bottom: 5rem
            margin-top: 0
        &__filter
            margin-bottom: 1rem