.advanced-select-row
	display: block
	margin: 5rem auto
	position: relative
	&_rows
		display: flex
		flex-direction: row
		flex-wrap: wrap
		.advanced-select
			display: block
			flex-grow: 1
			margin-top: 1.5rem
			margin-bottom: 1.5rem
			width: calc(33% - 3rem)
			&:first-child,
			&:nth-child(4n)
				margin-right: 3rem
			&:nth-child(3n)
				margin-left: 3rem
	&_tags
		display: block
		line-height: 1.75em
		position: relative
	@media screen and (max-width: $tabletbp)
		.wrapper
			.advanced-select
				width: calc(50% - 1.5rem)
				&:nth-child(odd)
					margin-right: 1.5rem
					margin-left: 0
				&:nth-child(even)
					margin-right: 0
					margin-left: 1.5rem
	@media screen and (max-width: $mobilebp)
		.wrapper
			.advanced-select
				width: 100%
				&:nth-child(odd),
				&:nth-child(even)
					margin-left: 0
					margin-right: 0