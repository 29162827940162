.content-module
	margin: 10rem auto
	&:last-child
		margin-bottom: 0
	@media screen and (max-width: $mobilebp)
		margin: 4rem auto

.copy-image-module + .copy-list-module
	margin-top: -10rem
	@media screen and (max-width: $mobilebp)
		margin-top: -4rem