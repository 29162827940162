.cct-answer-tile
	align-items: center
	border: .4rem solid $color-white
	display: flex
	gap: 1rem
	margin: 1.5rem
	padding: 2.5rem 3rem 2.3rem 3rem
	position: relative
	width: calc(25% - 3rem)
	+noselect
	&_check-radio
		justify-self: flex-end
		order: 2
		z-index: 10
	&_card
		display: block
		+perspective(1000px)
		position: relative
	&_content
		align-content: flex-start
		background-color: transparent
		display: flex
		flex-direction: row
		flex-wrap: nowrap
		overflow: hidden
		position: relative
		+transformstyle(preserve-3d)
		+transition(aspect-ratio .5s ease, background-color .5s ease, transform .5s ease)
		width: 100%
	&_front,
	&_back
		display: flex
		flex-direction: column
		min-width: 100%
		order: 1
		position: relative
		+transition(order .4s ease, z-index .5s ease)
		width: 100%
	&_back
		color: $color-black
		+getComponent('font-reg')
		font-size: 1.6rem
		line-height: 1.333em
		order: 2
		padding: 2rem
		pointer-events: none
		+transform(rotateY(180deg))
		z-index: -1
	&_text
		color: $color-white
		display: block
		flex-grow: 1
		font-size: 1.8rem
		+getComponent('font-demi')
		line-height: 1.556em
		order: 1
		position: relative
		text-align: center
		width: 100%
	&.image-tile
		border: none
		display: block
		padding: 0
		.cct-answer-tile
			&_image
				+backgroundcover
				background-position: center center
				background-repeat: no-repeat
				display: block
				padding-top: 100%
				width: 100%
				img
					display: block
					height: auto
					left: 0
					opacity: 0
					position: absolute
					top: 0
					width: 100%
					+noselect
				.image-copyright
						align-items: flex-start
						bottom: auto
						top: 1rem
			&_text
				margin-top: 1.5rem
				order: 2
		.cct-answer-tile_check-radio
			margin-top: calc(100% - .8rem)
			position: absolute
			right: .8rem
			+transform(translateY(-100%))
	&--flipable
		cursor: pointer
		display: block
		+transform(scale(1))
		+transition(transform .3s ease,width .3s ease)
		.cct-answer-tile
			&_content
				aspect-ratio: 1/1
		@media (hover:hover)
			&:hover
				+transform(scale(1.05))

	&--flipped.cct-answer-tile--flipable
		.cct-answer-tile
			&_content
				aspect-ratio: auto
				background-color: $color-white
				+transform(rotateY(180deg))
			&_front
				order: 2
				pointer-events: none
				z-index: -1
			&_back
				order: 1
				pointer-events: all
				z-index: 1

	@media screen and (max-width: $tabletbp)
		&,
		&.image-tile
			width: calc(33% - 3rem)
		&_back
			font-size: 1.4rem
	
	@media screen and (max-width: $mobilebp)
		&,
		&.image-tile
			width: calc(50% - 3rem)
			&.cct-answer-tile--flipped
				width: 100%
		&_back
			hyphens: auto