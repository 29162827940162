@keyframes progress-animated
	from
		width: 0%
	to
		width: 100%

.progress
	background-color: $color-grey-light
	display: block
	height: 2.4rem
	position: relative
	width: 100%
	&_inner
		background-color: $color-red
		display: block
		height: 100%
		max-width: 100%
		+transition(width .1s ease)
		position: relative
	&.animated
		.progress_inner
			+animation(progress-animated, .75s, infinite)