.link
	&, &:link, &:visited, &:active
		color: $color-red
		display: inline-flex
		font-size: inherit
		+getComponent('font-demi')
		margin-bottom: -.55em
		margin-right: .2em
		max-width: 100%
		padding-right: 1.7em
		position: relative
		text-decoration: none
		word-break: break-all
		&::before
			background-color: currentColor
			bottom: .1em
			content: ''
			display: block
			height: .1em
			left: calc(50% - .75em)
			opacity: 0
			position: absolute
			+transform(translateX(-50%))
			+transition(opacity .3s ease, width .3s ease)
			width: 0
		.icon
			display: block
			position: absolute
			right: .2em
			top: 45%
			+transform(translateY(-50%))
			+transition(transform .3s ease)
			z-index: 1
		&.link-phone
			.icon
				top: 40%
		&.link-icon
			.icon
				top: 45%
	@media (hover:hover)
		&:hover
			&::before
				opacity: 1
				width: calc(100% - 1.5em)
			&.link-internal
				.icon
					+transform(translate(.2em,-50%))
			&.link-external
				.icon
					+transform(translate(.2em,calc(-50% - .2em)))
			&.link-download,
			&.link-download-external
				.icon
					+transform(translateY(calc(-50% + .1em)))
			&.link-mail,
			&.link-phone,
			&.link-icon
				.icon
					+transform(translateY(-50%) scale(1.1))

