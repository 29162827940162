#header
	display: block
	padding: 6rem 0 3rem 0
	position: relative
	+transition(background-color .3s ease, padding .3s ease, top .3s ease)
	width: 100%
	z-index: $layer_header
	.wrapper
		max-width: $header-footer-maxwidth
	&_content
		display: flex
		flex-direction: row
		flex-wrap: nowrap
		position: relative
	&_logo
		display: block
		margin-right: 4rem
		position: relative
		&:focus
			outline: .2rem dotted $color-white
		&:active
			outline: none
		&,
		.icon
			height: auto		
			width: 30.7rem
	&_menu
		+mainmenu
		justify-content: flex-end
	&_burger-button
		cursor: pointer
		display: none
		height: 4.4rem
		position: absolute
		right: 0
		top: .6rem
		width: 4.4rem
		&_icon
			background-color: $color-red
			display: block
			height: .5rem
			left: 50%
			position: absolute
			top: 50%
			+transform(translate(-50%,-50%) scale(1))
			+transition(background-color .3s ease, transform .3s ease)
			width: 3.5rem
			&::before,
			&::after
				background-color: $color-red
				content: ''
				display: block
				height: .5rem
				left: 50%
				position: absolute
				width: 3.5rem
				+transform(translate(-50%,calc(-50% - .8rem)) rotate(0deg))
				+transition(transform .3s ease)
			&::after
				+transform(translate(-50%,calc(-50% + 1.3rem)) rotate(0deg))
		@media (hover:hover)
			&:hover
				#header_burger-button_icon
					+transform(translate(-50%,-50%) scale(1.1))
	&.invert
		#header_menu
			color: $color-white
	&.absolute
		left: 0
		position: absolute
		top: 2rem
	&.fixed
		left: 0
		position: fixed
		top: 0
	&.scrolled
		background-color: $color-white
		padding: 3rem 0
		#header_menu
			color: $color-black
	&.flyout-open
		#header_menu
			color: $color-black

	@media screen and (max-width: $tabletbp)
		&_logo
			z-index: 10
		&_menu,
		&.invert #header_menu
			background-color: $color-white
			color: $color-black
			font-size: 1.8rem
			left: 0
			min-height: 100vh
			opacity: 0
			padding: 0 4.2rem
			position: fixed
			top: 0
			+transform(translateY(-100%))
			+transition(opacity .3s ease, transform .3s ease)
			width: 100%
			ul
				display: block
				width: 100%
				li
					margin: 2.5rem 0
					text-align: left

		&_burger-button
			display: block
		&.menu-open
			#header
				&_menu
					opacity: 1
					+transform(translateY(0))
				&_burger-button
					&_icon
						background-color: transparent
						&::before
							+transform(translate(-50%,-50%) rotate(-45deg))
						&::after
							+transform(translate(-50%,-50%) rotate(45deg))

	@media screen and (max-width: $mobilebp)
		height: 7rem
		padding: 0
		&.scrolled
			padding: 0
		.wrapper
			padding: 0 3.2rem
		&_logo
			position: absolute
			top: 2rem
			&,
			.icon
				height: auto
				width: 17.1rem
		&_menu,
		&.invert #header_menu
			padding: 0 3rem
		&_burger-button
			height: 4.4rem
			right: -.9rem
			top: 1.3rem
			width: 4.4rem
			&_icon
				height: .4rem
				width: 2.6rem
				&::before,
				&::after
					height: .4rem
					width: 2.6rem
					+transform(translate(-50%,calc(-50% - .6rem)) rotate(0deg))
				&::after
					+transform(translate(-50%,calc(-50% + 1rem)) rotate(0deg))
		
		&.absolute
			top: 1.2rem
		&.fixed.scrolled
			top: 0