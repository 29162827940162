html
	font-size: 10px
	overflow-x: hidden
	width: 100%

body
	background-color: $color-white
	font-size: 1.6rem
	+getComponent('font-reg')

#site
	display: block
	min-height: 100vh
	position: relative
	width: 100%

#content
	display: block
	padding-bottom: 14rem
	position: relative
	width: 100%
	@media screen and (max-width: $mobilebp)
		padding-bottom: 7rem

.wrapper
	display: block
	margin: 0 auto
	max-width: $wrapper-maxwidth
	padding: 0 4rem
	position: relative
	width: 100%
	@media screen and (max-width: $mobilebp)
		padding: 0 1.7rem

.tooltip
	&:after
		+getComponent('font-reg')
	@media (hover:hover)
		&::after
			background-color: $color-red
			display: block
			color: $color-white
			content: attr(data-tooltip)
			font-size: .7em
			left: 10%
			opacity: 0
			padding: .5rem 1rem
			pointer-events: none
			position: absolute
			top: 0
			+transform(translateY(calc(-100% - 1rem)))
			+transition(opacity .3s ease)
			white-space: nowrap
			z-index: $layer_tooltips
		&:hover
			&::after
				opacity: 1

p
	margin: 1.5em auto
	&:first-child
		margin-top: 0
	&:last-child
		margin-bottom: 0

.image-copyright
	align-items: flex-end
	bottom: 1rem
	color: rgba($color-white, .75)
	display: flex
	+getComponent('font-demi')
	font-size: 1.4rem
	gap: 1rem
	justify-content: flex-end
	max-width: calc(100% - 1rem)
	position: absolute
	right: 1rem
	+noselect
	width: 100%
	z-index: $layer_copyright
	&__hint,
	button.image-copyright__hint
		-webkit-appearance: none
		appearance: none
		backdrop-filter: blur(1rem)
		background-color: transparent
		background-color: rgba($color-black, .5)
		border: none
		+borderradius(50%)
		color: rgba($color-white, .75)
		cursor: pointer
		font-size: 1em
		height: 2rem
		order: 2
		padding-top: .2em
		width: 2rem
	&__text
		background-color: rgba($color-black, .5)
		backdrop-filter: blur(1rem)
		+borderradius(.2rem)
		color: $color-white
		display: flex
		+getComponent('font-reg')
		font-size: 1.2rem
		max-width: calc(100% - 4rem)
		opacity: 0
		pointer-events: none
		padding: .4rem .6rem .5rem .6rem
		pointer-events: none
		position: relative
		text-align: center
		width: auto
		z-index: 500
	&.image-copyright--shown
		.image-copyright__text
			opacity: 1

=mainmenu
	align-items: center
	color: $color-black
	display: flex
	flex-grow: 1
	font-size: 1.4rem
	+getComponent('font-bold-obl')
	line-height: 1.7em
	ul
		display: flex
		flex-direction: row
		flex-wrap: wrap
		justify-content: flex-end
		position: relative

		li
			display: block
			margin: .5rem 2rem
			text-align: center
			a
				&, &:link, &:visited, &:active
					display: inline-block
					outline: none
					position: relative
					width: auto
					&::before
						background-color: $color-red
						bottom: 0
						content: ''
						display: block
						height: .4rem
						left: 50%
						opacity: 0
						position: absolute
						+transform(translateX(-50%))
						+transition(opacity .3s ease, width .3s ease)
						width: 0
				&:focus
					outline: .2rem dotted currentColor
					&:active
						outline: none
			&.active,
			&.open
				a
					&::before
						opacity: 1
						width: 100%
			@media (hover:hover)
				&:hover
					a
						&::before
							opacity: 1
							width: 100%
	@media screen and (max-width: $mobilebp)
		ul
			li
				&.open
					a
						&::before
							opacity: 0
							width: 0

=richtext-liststyle
	ul,
	ol
		display: block
		list-style: none
		position: relative
	ul
		li
			margin-bottom: .5em
			padding-left: 1.3em
			position: relative
			&:last-child
				margin-bottom: 0
			&::before
				color: inherit
				content: '\25CF'
				display: block
				font-size: 1em
				left: 0
				position: absolute
				top: 0
	ol
		li
			display: flex
			flex-direction: row
			flex-wrap: nowrap
			margin-bottom: .5em
			counter-increment: richtext-item
			position: relative
			&::last-child
				margin-bottom: 0
			&::before
				color: inherit
				content: counter(richtext-item)'.'
				display: inline-block
				font-size: 1em
				margin-right: .5em
				position: relative

=reader-image
	display: block
	height: auto
	opacity: 0
	position: absolute
	width: 100%
