.cookie-banner
    background-color: $color-white
    bottom: 0
    +boxshadow(0 -1rem 1rem 0 $shadow_cookie-banner)
    display: none
    left: 0
    padding: 1.6rem 2.5rem .4rem 2.5rem
    position: fixed
    width: 100%
    z-index: $layer-cookie-banner
    .wrapper
        max-width: $header-footer-maxwidth
    &.show
        display: block
    &_text
        color: $color-black
        font-size: 1.4rem
        margin-bottom: .8rem
    &_interaction
        display: flex
        flex-direction: row
        flex-wrap: nowrap
        justify-content: space-between
        position: relative
        width: 100%
        &_buttons,
        &_links
            display: flex
            flex-direction: row
            flex-wrap: wrap
            margin-right: -.8rem
            position: relative
            &_accept,
            &_decline
                margin: .8rem 2rem
        &_links
            align-items: center
            .link
                margin-right: 2rem
    
    @media screen and (max-width: $mobilebp)
        padding-top: 2rem
        &_text
            margin-bottom: 2.5rem
        &_interaction
            display: block
            &_buttons,
            &_links
                flex-wrap: wrap
                justify-content: center
                margin: 1rem auto
                width: 100%
            &_links
                margin-bottom: 2rem
