.header-flyout
	background-color: $color-white
	+boxshadow(0 0 0 0 $shadow_header-flyout)
	display: block
	left: 0
	min-height: 40rem
	opacity: 0
	padding-bottom: 4rem
	position: absolute
	top: -2rem
	+transform(translateY(-100%))
	+transition(box-shadow .3s ease, opacity .3s ease, transform .3s ease)
	width: 100%
	&.active
		+boxshadow(0 .4rem 2.6rem .5rem $shadow_header-flyout)
		opacity: 1
		+transform(translateY(0))
	&_closer
		cursor: pointer
		display: flex
		flex-direction: row
		flex-wrap: nowrap
		justify-content: flex-end
		position: relative
		.icon
			color: $color-red
			font-size: 2.5rem
			+transform(scale(1))
			+transition(transform .3s ease)
			@media (hover:hover)
				&:hover
					+transform(scale(1.1))
	&_entry
		display: none
		position: relative
		width: 100%
		&_parent
			color: $color-black
			display: flex
			flex-direction: row
			flex-wrap: nowrap
			position: relative
			+transition(color .3s ease)
			.icon
				color: $color-red
				font-size: 3rem
				margin-left: 4rem
				min-width: 3rem
				top: -.15em
				+transition(margin-left .3s ease)
			@media (hover:hover)
				&:hover
					color: $color-red
					.icon
						margin-left: 4.25rem
		ul
			display: flex
			flex-direction: row
			flex-wrap: wrap
			list-style: none
			margin: 0 -1.5rem
			position: relative
			width: calc(100% + 3rem)
			li
				border: .5rem solid transparent
				display: block
				margin: 1.5rem
				position: relative
				+transition(border-color .3s ease)
				width: 17.5rem
				@media (hover:hover)
					&:hover
						border-color: $color-red
		&_img
			background-color: $color-grey-light
			+backgroundcover()
			display: block
			padding-top: 70%
			width: 100%
			img
				display: block
				opacity: 0
				position: absolute
				width: 100%
		&_name
			color: $color-black
			display: block
			font-size: 1.6rem
			+getComponent('font-demi')
			+hyphensauto
			line-height: 1.625em
			padding: 1.2rem
			position: relative
							
		&.active
			display: block
	@media screen and (max-width: $tabletbp)
		display: none
