#page
	&_503
		display: block
		margin: 8rem auto
		position: relative
		&_logo
			display: block
			margin: 1rem auto 5rem auto
			position: relative
			text-align: center
			width: 100%
			.icon
				height: auto
				width: 50rem
		@media screen and (max-width: $mobilebp)
			&_logo
				.icon
					max-width: 75vw
					width: 30rem


	&_404
		display: block
		margin: 8rem auto 0 auto
		position: relative



	&_offer-distribution
		@media screen and (max-width: $mobilebp)
			.breadcrumbs
				margin: 0 1rem


	&_offer-detail
		@media screen and (max-width: $mobilebp)
			&_breadcrumbs
				display: none