.offer-row
	color: $color-black
	background-color: $color-grey-light
	display: flex
	flex-direction: row
	flex-wrap: no-wrap
	line-height: 1.625em
	margin: 2rem auto
	max-width: $wrapper-maxwidth
	padding: 2.4rem 4rem 2rem 4rem
	position: relative
	+transition(background-color .3s ease, color .3s ease)
	width: 100%
	&.no-match,
	&.pagination-hide,
	&.hide
		display: none
	&_left,
	&_right
		display: block
		position: relative
	&_left
		width: 70%
	&_right
		padding: 0 3.5rem 0 2rem
		text-align: right
		width: 30%
		.icon
			color: $color-red
			font-size: 3rem
			position: absolute
			top: 0
			right: -1.3em
			+transition(color .3s ease)
	&_area,
	&_vacancy
		display: block
		position: relative
	&_title
		.headline
			+getComponent('font-bold')
	&_type,
	&_vacancy
		margin-top: 1.7rem
	@media (hover:hover)
		&:hover
			background-color: $color-red
			color: $color-white
			.offer-row
				&_left,
				&_right
					.icon
						color: $color-white

	@media screen and (max-width: $mobilebp)
		display: block
		margin: 3rem auto
		padding: 1.6rem
		&_left,
		&_right
			width: 100%
		&_right
			margin-top: 1.5rem
			.icon
				font-size: 2rem
				right: -1.5em
				top: .2em
		&_type,
		&_vacancy
			margin-top: 0