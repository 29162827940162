
// ###
// #   CSS3 SASS LIB
// #
// #   Author: monodigital WBA GmbH + Co. KG | Monoment
// #   Developer: Alex Blödorn
// #   Version: 0.1.1
// #   Date: 041121
// ###

// PREFIXES
$inlinesvg:			'data:image/svg+xml;utf8,'

// ANIMATION
=animation($name, $time, $count)
	-webkit-animation:  $name $time $count
	-moz-animation:     $name $time $count
	-o-animation:       $name $time $count
	animation:          $name $time $count

=animate($args...)
	-webkit-animation:  $args
	-moz-animation:     $args
	-o-animation:       $args
	animation:          $args

@mixin noanimation
	-webkit-animation:  none
	-moz-animation:     none
	-o-animation:       none
	animation:          none

// APPEARANCE
=appearance($property)
	-moz-appearance: $property
	-webkit-appearance: $property
	appearance: $property

// ARROWS
=arrow_up($size: 6px, $color: #000)
	width: 0
	height: 0
	border-left: $size solid transparent
	border-right: $size solid transparent
	border-bottom: $size solid $color

=arrow_down($size: 6px, $color: #000)
	width: 0
	height: 0
	border-left: $size solid transparent
	border-right: $size solid transparent
	border-top: $size solid $color

=arrow_right($size: 6px, $color: #000)
	width: 0
	height: 0
	border-top: $size solid transparent
	border-bottom: $size solid transparent
	border-left: $size solid $color

=arrow_left($size: 6px, $color: #000)
	width: 0
	height: 0
	border-top: $size solid transparent
	border-bottom: $size solid transparent
	border-right: $size solid $color

// BACKGROUND
@mixin backgroundcover
	-webkit-background-size: cover
	-moz-background-size: cover
	-o-background-size: cover
	background-size: cover
@mixin backgroundcontain
	-webkit-background-size: contain
	-moz-background-size: contain
	-o-background-size: contain
	background-size: contain

// BORDER RADIUS
=borderradius($radius)
	-moz-border-radius: $radius
	-webkit-border-radius: $radius
	border-radius: $radius
=borderradiustl($radius)
	-moz-border-top-left-radius: $radius
	-webkit-border-top-left-radius: $radius
	border-top-left-radius: $radius
=borderradiustr($radius)
	-moz-border-top-right-radius: $radius
	-webkit-border-top-right-radius: $radius
	border-top-right-radius: $radius
=borderradiusbl($radius)
	-moz-border-bottom-left-radius: $radius
	-webkit-border-bottom-left-radius: $radius
	border-bottom-left-radius: $radius
=borderradiusbr($radius)
	-moz-border-bottom-right-radius: $radius
	-webkit-border-bottom-right-radius: $radius
	border-bottom-right-radius: $radius

// BOX SHADOW
=boxshadow($args...)
	-moz-box-shadow: $args
	-webkit-box-shadow: $args
	box-shadow: $args
=boxshadowo($x, $y, $blur, $spread, $color)
	-moz-box-shadow: $x $y $blur $spread $color
	-webkit-box-shadow: $x $y $blur $spread $color
	box-shadow: $x $y $blur $spread $color
=boxshadowi($x, $y, $blur, $spread, $color)
	-moz-box-shadow: inset $x $y $blur $spread $color
	-webkit-box-shadow: inset $x $y $blur $spread $color
	box-shadow: inset $x $y $blur $spread $color
=boxshadowio($xo, $yo, $bluro, $spreado, $coloro, $xi, $yi, $bluri, $spreadi, $colori)
	-moz-box-shadow: $xo $yo $bluro $spreado $coloro, inset $xi $yi $bluri $spreadi $colori
	-webkit-box-shadow: $xo $yo $bluro $spreado $coloro, inset $xi $yi $bluri $spreadi $colori
	box-shadow: $xo $yo $bluro $spreado $coloro, inset $xi $yi $bluri $spreadi $colori

// BOX-SIZING
=boxsizing($method)
	-webkit-box-sizing: $method
	-moz-box-sizing: $method
	box-sizing: $method
@mixin borderbox
	+boxsizing(border-box)
@mixin contentbox
	+boxsizing(content-box)
@mixin flexbox
	display: -webkit-box
	display: -moz-box
	display: -ms-flexbox
	display: -webkit-flex
	display: flex

// GRADIENTS
// degree -> e.g.: 30deg
// type: horizontal, vertical, diagonaltop, diagonalbot, radial
// color1/color2: #hexcode
// tuples: #hexcode percent -> e.g: #fff 10%, #000 100%
=gradient($type, $color1, $color2, $tuples...)
	@if $type == horizontal
		background: $color1
		background: -moz-linear-gradient(left,  $tuples)
		background: -webkit-linear-gradient(left,  $tuples)
		background: linear-gradient(to right,  $tuples)
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$color1}', endColorstr='#{$color2}',GradientType=1 )

	@if $type == vertical
		background: $color1
		background: -moz-linear-gradient(top,  $tuples)
		background: -webkit-linear-gradient(top,  $tuples)
		background: linear-gradient(to bottom,  $tuples)
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$color1}', endColorstr='#{$color2}',GradientType=0 )

	@if $type == diagonaltop
		background: $color1
		background: -moz-linear-gradient(-45deg,  $tuples)
		background: -webkit-linear-gradient(-45deg,  $tuples)
		background: linear-gradient(135deg,  $tuples)
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$color1}', endColorstr='#{$color2}',GradientType=1 )

	@if $type == diagonalbot
		background: $color1
		background: -moz-linear-gradient(45deg,  $tuples)
		background: -webkit-linear-gradient(45deg,  $tuples)
		background: linear-gradient(45deg,  $tuples)
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$color1}', endColorstr='#{$color2}',GradientType=1 )

	@if $type == radial
		background: $color1
		background: -moz-radial-gradient(center, ellipse cover,  $tuples)
		background: -webkit-radial-gradient(center, ellipse cover,  $tuples)
		background: radial-gradient(ellipse at center,  $tuples)
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$color1}', endColorstr='#{$color2}',GradientType=1 )
	
	@if $type == radial-left
		background: $color1
		background: -moz-radial-gradient(left, ellipse cover,  $tuples)
		background: -webkit-radial-gradient(left, ellipse cover,  $tuples)
		background: radial-gradient(ellipse at left,  $tuples)
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$color1}', endColorstr='#{$color2}',GradientType=1 )

	@if $type == radial-right
		background: $color1
		background: -moz-radial-gradient(right, ellipse cover,  $tuples)
		background: -webkit-radial-gradient(right, ellipse cover,  $tuples)
		background: radial-gradient(ellipse at right,  $tuples)
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$color1}', endColorstr='#{$color2}',GradientType=1 )

	@if $type == radial-top
		background: $color1
		background: -moz-radial-gradient(top, ellipse cover,  $tuples)
		background: -webkit-radial-gradient(top, ellipse cover,  $tuples)
		background: radial-gradient(ellipse at top,  $tuples)
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$color1}', endColorstr='#{$color2}',GradientType=1 )

	@if $type == radial-bottom
		background: $color1
		background: -moz-radial-gradient(bottom, ellipse cover,  $tuples)
		background: -webkit-radial-gradient(bottom, ellipse cover,  $tuples)
		background: radial-gradient(ellipse at bottom,  $tuples)
		filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$color1}', endColorstr='#{$color2}',GradientType=1 )

=lineargradient($degree, $color1, $color2, $tuples...)
	background: $color1
	background: -moz-linear-gradient($degree,  $tuples)
	background: -webkit-linear-gradient($degree,  $tuples)
	background: linear-gradient($degree,  $tuples)
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#{$color1}', endColorstr='#{$color2}',GradientType=1 )

=radialgradient($position, $backupcolor, $tuples...)
	background: $backupcolor
	background: -moz-radial-gradient(at $position,$tuples)
	background: -webkit-radial-gradient($position,$tuples)
	background: radial-gradient(at $position,$tuples)


// CLEARS
@mixin clearafter
	&::after
		content: ''
		position: relative
		display: block
		clear: both

// PERSPECTIVE
=perspective($value)
	-webkit-perspective: $value
	-moz-perspective: $value
	perspective: $value

// TEXT
=hyphens($property)
	-webkit-hyphens: $property
	-moz-hyphens: $property
	hyphens: $property
@mixin hyphensauto
	+hyphens(auto)
@mixin noselect
	-webkit-touch-callout: none
	-webkit-user-select: none
	-khtml-user-select: none
	-moz-user-select: none
	-ms-user-select: none
	user-select: none
@mixin smoothfont
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale
@mixin nosmoothfont
	-webkit-font-smoothing: initial
	-moz-osx-font-smoothing: initial
@mixin breakafter
	&::after
		content: '\A'
		white-space: pre

=outlinedText($outline, $unit, $lineColor)
	text-shadow: #{-$outline} + $unit #{-$outline} + $unit 0 $lineColor, #{$outline} + $unit #{-$outline} + $unit 0 $lineColor, #{-$outline} + $unit #{$outline} + $unit 0 $lineColor, #{$outline} + $unit #{$outline} + $unit 0 $lineColor

=userselect($property)
	-webkit-touch-callout: $property
	-webkit-user-select: $property
	-khtml-user-select: $property
	-moz-user-select: $property
	-ms-user-select: $property
	user-select: $property

// TRANSFORM
=transform($property)
	-webkit-transform: $property
	-ms-transform: $property
	transform: $property
=transformorigin($args...)
	-ms-transform-origin: $args
	-moz-transform-origin: $args
	-webkit-transform-origin: $args
	transform-origin: $args
=transformstyle($property)
	-webkit-transform-style: $property
	-moz-transform-style: $property
	transform-style: $property

// TRANSITIONS
=transition($args...)
	-webkit-transition: $args
	-moz-transition: $args
	-o-transition: $args
	transition: $args


// ###
// #
// # VERSIONING
// #
// # 0.1.1 - 041121
// # - added radial gradient function
// # - added outlinedText function
// #
// # 0.1.0 - 211021
// # - added hyphens function and hyphensauto mixin
// #
// # 0.0.9 - 030921
// # - added radial-left, radial-right, radial-top and radial-bottom to the gradient generator function
// #
// # 0.0.8 - 150621
// # - added userselect function with arg to support cross browser user-select
// #
// # 0.0.7 - 081220
// # - added animate function with multiple args support to enable e.g. animation-count etc.
// #
// # 0.0.6 - 220420
// # - fixed missing spread points for linear gradient var $tuples
// #
// # 0.0.5 - 060320 
// # - added gradient functions
// # 
// # 0.0.4 - 200120
// # - added appearance
// # - sort alphabetical
// #
// # 0.0.3 - 190910
// # - added arrow presets
// #
// # 0.0.2 - 190909
// # - replaced wrongly used extends with correct mixins
// #
// # 0.0.1 - 190730
// # - project start
// # 
// ###