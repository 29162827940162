.advanced-select
	border: .3rem solid $color-grey
	color: $color-black
	display: inline-block
	outline: none
	padding: 1.6rem 1.8rem
	padding-right: 5rem
	position: relative
	+transition(border-color .3s ease)
	&_label
		color: $color-black
		display: inline-block
		font-size: 1.4rem
		left: 0
		position: absolute
		top: -2.5rem
	&_box
		cursor: pointer
		display: block
		font-size: 1.6rem
		+getComponent('font-md')
		position: relative
		+noselect
		&_title
			&:before
				content: attr(data-title)
				margin-right: .3em
		.icon
			color: $color-black
			font-size: 2.9rem
			position: absolute
			right: -3.7rem
			top: 50%
			+noselect
			+transform(translateY(-50%) rotate(180deg))
			+transition(color .3s ease, transform .3s ease)
	&_content
		background-color: $color-white
		border: .3rem solid $color-grey
		border-top: none
		display: block
		left: -.3rem
		max-height: 0
		opacity: 0
		overflow: hidden
		padding: 0 1.8rem 1.6rem 1.8rem
		position: absolute
		top: 100%
		+transformorigin(top center)
		+transition(border-color .3s ease, max-height .2s ease, opacity .3s ease)
		width: calc(100% + .6rem)
		z-index: 10
		&_section
			display: block
			margin: 2rem 0
			position: relative
			&:first-child
				margin-top: 1rem
			&_headline
				font-size: 1.6rem
				+getComponent('font-md')
				margin-bottom: 1rem
	&_option
		margin: 1rem 0
		&.zeroed
			color: $color-grey
	&_close,
	button.advanced-select_close
		width: 100%
	&.open
		z-index: 11
		.advanced-select_box
			.icon
				+transform(translateY(-50%) rotate(0deg))
		.advanced-select_content
			max-height: 75vh
			opacity: 1
			overflow-y: auto
	&.active
		&,
		.advanced-select_content
			border-color: $color-red
		.advanced-select_box
			.icon
				color: $color-red
	&:focus
		border-color: $color-black
		.advanced-select_content
			border-color: $color-black
	&.disabled
		border-color: $color-black-25
		color: $color-black-25
		pointer-events: none
		.advanced-select_content
			max-height: 0
			opacity: 0
			+transform(scaleY(0))
		.advanced-select_box
			.icon
				color: $color-black-25
	
	&.plain-white
		background-color: $color-white
		border-color: $color-white
		.advanced-select
			&_label
				color: $color-white
			&_box
				+getComponent('font-demi')
				.icon
					color: $color-red
			&_content
				border-color: $color-black
		&.open
			border-color: $color-black
			.advanced-select
				&_box
					.icon
						color: $color-black