.accordion
	display: block
	margin: 0 auto
	position: relative
	width: 100%
	&_headline
		text-align: center
		margin-bottom: 4rem
		padding: 0 3rem
	&_row
		border-bottom: .4rem solid transparent
		display: block
		margin: 1.6rem auto
		max-width: 84.9rem
		position: relative
		width: 100%
		&_header
			background-color: $color-grey-light
			color: $color-black
			cursor: pointer
			display: block
			font-size: 1.9rem
			+getComponent('font-bold')
			padding: 2.1rem 9rem 1.9rem 3rem
			position: relative
			+transition(background-color .3s ease, color .3s ease)
			width: 100%
			&_icon
				display: block
				color: $color-red
				height: 3rem
				opacity: 1
				position: absolute
				right: 3rem
				top: 50%
				+transform(translateY(-50%))
				+transition(opacity .3s ease)
				width: 3rem
				&.open
					opacity: 0
				.icon
					height: 100%
					width: 100%
			@media (hover:hover)
				&:hover
					background-color: $color-red
					color: $color-white
					.accordion_row_header_icon
						color: $color-white
		.headline
			margin-top: .75rem
		&_content
			border: .4rem solid transparent
			border-top: none
			+borderbox
			display: block
			font-size: 1.6rem
			line-height: 1.625em
			max-height: 0
			opacity: 0
			overflow: hidden
			padding: 0 4rem
			position: relative
			+transformorigin(top center)
			+transition(border-color .3s ease, max-height .3s ease, opacity .3s ease, padding .3s ease)
		&.open
			.accordion_row
				&_header
					background-color: $color-red
					color: $color-white
					&_icon
						color: $color-white
						&.open
							opacity: 1
						&.closed
							opacity: 0
				&_content
					border-color: $color-red
					max-height: 9999rem
					opacity: 1
					padding-bottom: 4rem
					padding-top: 4rem


	@media screen and (max-width: $mobilebp)
		&_headline
			padding: 0 1.6rem
			text-align: left
		&_row
			max-width: inherit
			+transition(border-color .3s ease)
			&_header
				padding: 2.4rem 8rem 2.5rem 1.6rem
				&_icon
					right: 2.4rem
			&_content
				padding: 0 1.6rem
			&.open
				border-color: $color-red
				.accordion_row
					&_content
						border-color: transparent
						padding-top: 2rem
						padding-bottom: 2rem
