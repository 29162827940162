.icon
	display: inline-block
	fill: currentColor
	height: 1em
	line-height: 0
	position: relative
	+userselect(all)
	vertical-align: middle
	width: 1em
	&-color
		&_black
			color: $color-black
		&_white
			color: $color-white
		&_red
			color: $color-red

	