.cct-choice-tiles
	display: block
	position: relative
	width: 100%
	&_headline
		display: block
		position: relative
		text-align: center
		width: 100%
	&_answers
		display: flex
		flex-direction: row
		flex-wrap: wrap
		justify-content: center
		margin: 3.5rem -1.5rem 0 -1.5rem
		position: relative
		width: calc(100% + 3rem)