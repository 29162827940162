.offer-counts
	color: inherit
	display: block
	font-size: 1.9rem
	line-height: 1.4em
	margin: 1rem auto
	position: relative
	text-align: center
	width: 100%
	b
		+getComponent('font-bold')
	&_tags
		.tag
			font-size: inherit
			margin: 0 .1em 0 0
	&_default-location,
	&_hint
		&.hide
			display: none
	&_hint
		margin-top: 1rem