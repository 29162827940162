.cct-map
	display: block
	position: relative
	&_content
		align-items: center
		display: flex
		flex-direction: row
		flex-wrap: nowrap
		margin: 3.5rem 0
		position: relative
		width: 100%
		&_boxes
			display: block
			position: relative
			width: 67%
			&_normal
				columns: 2
				display: block
				position: relative
				width: 100%
			&_box
				cursor: pointer
				display: flex
				flex-direction: row
				flex-wrap: nowrap
				padding: .7rem
				position: relative
				width: calc(50% - 1.4rem)
				&_text
					color: $color-white
					display: inline-flex
					flex-grow: 1
					font-size: 1.8rem
					+getComponent('font-demi')
					line-height: 1.556em
					margin-left: 2rem
					order: 1
					position: relative
					text-align: left
					+noselect
					width: 100%
					white-space: nowrap
			&_all
				.cct-map_content_boxes_box
					background-color: white
					color: black
					display: inline-flex
					margin-top: 2rem
					padding: 1rem 1.8rem .8rem 0
					width: auto
				.cct-check-radio
					opacity: 0
					width: 0
				.cct-map_content_boxes_box_text
					color: $color-black
		&_map
			display: block
			position: relative
			width: 33%

	@media screen and (max-width: $tabletbp)
		&_content
			&_boxes,
			&_map
				width: 50%
			&_boxes
				&_normal
					columns: 1
	@media screen and (max-width: $mobilebp)
		&_content
			flex-wrap: wrap
			&_boxes,
			&_map
				width: 100%
			&_boxes
				padding-bottom: 2rem
				&_normal
					columns: 2
				&_box
					&_text
						font-size: 1.6rem
						line-height: 1.8em
						white-space: normal
				&_all
					.cct-map_content_boxes_box
						width: auto
						&_text
							white-space: nowrap
			&_map
				.map
					margin: 3rem auto 0 auto
					max-width: 40rem
		