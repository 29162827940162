.cct-select-box
    align-items: center
    display: flex
    flex-direction: row
    flex-wrap: nowrap
    max-width: 100%
    position: relative
    &_prefix
        +getComponent('font-bold-obl')
        font-size: 2.5rem
        margin-right: 3rem
    .advanced-select
        flex-grow: 1
        max-width: calc(100% - 5rem)
        &_choosen
            display: none
        &_box
            &_title
                max-width: 100%
                overflow: hidden
                text-overflow: ellipsis
                white-space: nowrap
        &_option
            label
                hyphens: auto
    &_title-option
        & + &
            &:before
                content: '+'
                margin: 0 .3em
    
    @media screen and (max-width: $mobilebp)
        flex-wrap: wrap
        &_prefix
            display: block
            margin-bottom: 2rem
            margin-right: 0
            text-align: left
            width: 100%
        .advanced-select
            &_label
                bottom: -2.5rem
                top: auto