.cct-select-boxes
	display: block
	position: relative
	&_headline
		display: block
		margin-bottom: 7rem
		position: relative
		text-align: center
		width: 100%
	&_answers
		display: flex
		flex-direction: row
		flex-wrap: wrap
		margin: 0 -2.2rem
		position: relative
		width: calc(100% + 4.4rem)
		&_answer
			display: block
			margin: 2.2rem
			position: relative
			width: calc(50% - 4.4rem)
	
	@media screen and (max-width: $mobilebp)
		&_answers
			margin: 0
			width: 100%
			&_answer
				margin: 2.2rem 0
				width: 100%