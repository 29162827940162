.calendar-entry
    background-color: $color-grey-light
    cursor: pointer
    display: flex
    padding: 4rem 2rem 3rem 2rem
    position: relative

    &:focus-visible
        outline: .2rem dashed $color-red
        outline-offset: .2rem

    time
        display: inline-block
        &.calendar-entry__date__end
            display: block

    &__icon
        color: $color-red
        display: block
        min-width: 4rem
        position: relative
        width: 5%
        .icon
            font-size: 4rem

    &__date
        display: block
        font-size: 1.6rem
        line-height: 1.6em
        min-width: 20%
        padding-left: 4rem
        position: relative
        width: 20%
        span
            display: inline-block
            margin-left: .3em
            position: relative  
        &__time
            span
                margin: 0 .3em
        &__daily-time
            font-size: .9em
    
    &__control
        &-link
            &--less
                display: none
                margin-top: 2.5rem
    
    &__content
        display: flex
        flex-direction: column
        line-height: 1.33em
        min-width: 75%
        padding-left: 4rem
        position: relative
        width: 75%
        &__type
            +getComponent('font-bk')
            font-size: 1.8rem
            margin-bottom: .5rem
        &__title
            .headline
                font-size: 1.8rem
        &__text
            font-size: 1.6rem
            margin-top: 2.5rem
            &__box
                display: none
                line-height: 1.33em
                .link
                    display: inline-block
                    overflow: hidden
                    text-overflow: ellipsis
                    white-space: nowrap
                    
            &--shown
                .calendar-entry
                    &__content__text__box
                        display: block
                    &__control-link
                        &--less
                            display: block
                        &--more
                            display: none
    
    @media screen and (max-width: $mobilebp)
        flex-wrap: wrap
        padding: 3rem 1.5rem 2.5rem 1.5rem
        &__icon
            width: 5rem
        &__date
            padding-left: 2rem
            width: calc(100% - 5rem)
        &__content
            margin-top: 2rem
            padding-left: 0
            width: 100%
            &__type
                font-size: 1.6rem
            &__title
                .headline
                    font-size: 1.6rem
            &__text
                font-size: 1.6rem
                margin-top: 1.5rem
        &__control
            &-link
                &--less
                    margin-top: 1.5rem