#footer
	background-color: $color-grey-light
	border-top: 1.6rem solid $color-red
	display: block
	margin-top: 4rem
	min-height: 10rem
	padding: 5.5rem 0 10rem 0
	position: relative
	width: 100%
	.wrapper
		max-width: $header-footer-maxwidth
	&_content
		display: block
		position: relative
		width: 100%
	&_main-row,
	&_sub-row
		display: flex
		flex-direction: row
		flex-wrap: nowrap
		position: relative
		width: 100%
	&_main-row
		margin-bottom: 3.5rem
	&_sub-row
		align-items: baseline
		font-size: 1.4rem
		justify-content: space-between
	&_logo
		display: block
		margin-right: 4rem
		position: relative
		&:focus
			outline: .2rem dotted $color-black
		&,
		.icon
			height: auto
			width: 32.2rem
		&-mobile
			display: none
			margin-bottom: 2.2rem
			.icon
				height: auto
				width: 17.1rem
	&_main-menu
		+mainmenu
		justify-content: flex-end
	&_copyright
		margin-top: 1rem
		&-mobile
			display: none
			font-size: 1.4rem
			margin-top: 6rem
	&_sub-menus
		align-items: center
		display: flex
		flex-direction: row
		flex-wrap: wrap
		justify-content: flex-end
		&_link-menu,
		&_social-menu
			color: $color-black
			display: flex
			ul
				display: flex
				flex-direction: row
				flex-wrap: wrap
				justify-content: space-around
				position: relative
		&_link-menu
			flex-grow: 1
			ul
				li
					display: block
					margin: 1rem 2rem
					text-align: center
					a
						&, &:link, &:visited, &:active
							+transition(color .3s ease)
							outline: none
						&:focus
							outline: .2rem dotted currentColor
						&:active
							outline: none
					&.active
						a
							color: $color-red
					@media (hover:hover)
						&:hover
							a:hover
								color: $color-red
		&_social-menu
			margin-left: 4rem
			ul
				li
					margin: 0 1rem
					+transform(scale(1))
					+transition(transform .3s ease)
					a
						&, &:link, &:visited, &:active
							color: $color-red
							font-size: 3.4rem
						&:focus
							outline: none
							svg
								border: .2rem dotted $color-black
								+borderradius(50%)
						&:active
							svg
								border: none
								
					@media (hover:hover)
						&:hover
							+transform(scale(1.1))
	&_social-menu-mobile
		display: none
		margin-bottom: 4rem
		width: 100%
		ul
			display: flex
			flex-direction: row
			flex-wrap: wrap
			justify-content: center
			li
				color: $color-red
				font-size: 3.4rem
				margin: 1rem
				+transform(scale(1))
				+transition(transform .3s ease)
				a
					&:focus
						outline: none
						svg
							border: .2rem dotted $color-black
							+borderradius(50%)
					&:active
						svg
							border: none
				@media (hover:hover)
					&:hover
						+transform(scale(1.1))

	@media screen and (max-width: $mobilebp)
		padding: 3.2rem 0 5.2rem 0
		.wrapper
			padding: 0 5rem
		&_logo,
		&_copyright,
		&_sub-menus_social-menu
			display: none
		&_logo,
		&_copyright
			&-mobile
				display: block
		&_main-row,
		&_sub-row
			display: block
			margin-bottom: 0
			width: 100%
		&_sub-menus
			display: block
		&_main-menu
			font-size: 1.6rem
		&_main-menu,
		&_sub-menus_link-menu
				display: block
				ul
					display: block
					li
						margin: 2rem 0
						text-align: left
		&_social-menu-mobile
			display: block