@mixin range-thumb
	-webkit-appearance: none
	appearance: none
	background-color: $color-white
	border: 1rem solid $color-red-dark
	+borderradius(50%)
	+borderbox
	cursor: grab
	height: 5rem
	width: 5rem
	z-index: 1

.range
	display: inline-block
	position: relative
	width: 100%
	&_bgrd
		background: $color-white-a60
		+borderradius(.7rem)
		display: block
		height: 1.4rem
		left: 0
		pointer-events: none
		position: absolute
		top: 0
		width: 100%
	input[type="range"]
		-webkit-appearance: none
		appearance: none
		background: transparent
		height: 1.4rem
		outline: none
		position: relative
		width: 100%
		&:focus
			//outline: .1rem dashed $color-white
		&::-webkit-slider-thumb
			+range-thumb
		&::-moz-range-thumb
			+range-thumb
		&::ms-thumb
			+range-thumb