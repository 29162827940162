.open-offers
	display: block
	margin: 0 auto
	max-width: $wrapper-maxwidth
	width: 100%
	&_headline
		margin-bottom: 4.5rem
		text-align: center
	&_button
		display: flex
		flex-direction: row
		flex-wrap: nowrap
		justify-content: flex-end
		margin-top: 4rem

	@media screen and (max-width: $mobilebp)
		&_button
			justify-content: center