#page-frame
	border-top: 2rem solid $color-red
	display: block
	position: relative
	&::before,
	&::after
		background-color: $color-red
		content: ''
		display: block
		height: 50vh
		left: 0
		position: absolute
		top: 0
		width: 2rem
		z-index: $layer_page-frame
	&::after
		left: auto
		right: 0
	@media screen and (max-width: $mobilebp)
		border-width: 1.2rem
		&::before,
		&::after
			width: 1.2rem