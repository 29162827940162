.iterator-button
	align-items: center
	background-color: $color-grey-dark
	border: .4rem solid $color-grey-dark
	color: $color-white
	cursor: pointer
	display: flex
	flex-direction: row
	flex-wrap: nowrap
	font-size: 1.6rem
	+getComponent('font-demi')
	outline: none
	padding: 1.2rem 1.8rem 1rem 2.6rem
	position: relative
	+transition(background-color .3s ease, border-color .3s ease, border-width .3s ease, color .3s ease, padding .3s ease, opacity .3s ease, transform .3s ease)
	+noselect()
	width: auto
	@media (hover:hover)
		&:hover
			background-color: $color-white
			color: $color-grey-dark
	&_icon
		color: inherit
		display: block
		height: 2.5rem
		margin-left: 1rem
		order: 2
		position: relative
		.icon
			height: 2.5rem
			width: 2.5rem
	&_text
		display: block
		order: 1
		margin-top: .15em
		position: relative
	&.prev
		background-color: $color-white
		border-color: $color-white
		color: $color-red
		padding: 1.2rem 2.6rem 1rem 1.8rem
		.iterator-button
			&_icon
				margin-left: 0
				margin-right: 1rem
				order: 1
				.icon
					+transform(scale(-1,1))
			&_text
				order: 2
		@media (hover:hover)
			&:hover
				background-color: $color-red
				color: $color-white
	.disable > &,
	&.disabled
		opacity: .66
		pointer-events: none
