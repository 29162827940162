.select
	border: .3rem solid currentColor
	color: currentColor
	display: inline-block
	outline: none
	padding: 1.6rem 1.8rem
	padding-right: 5rem
	position: relative
	+transition(border-color .3s ease)
	&_box
		cursor: pointer
		display: block
		font-size: 1.6rem
		+getComponent('font-md')
		position: relative
		+noselect
		&_label
			display: inline-block
			left: 0
			position: relative
			top: 0
			white-space: nowrap
			z-index: 11
			.required &
				&::after
					content: '*'
					display: block
					font-size: .7em
					position: absolute
					right: -.6em
					top: -.3em
			.selected &
				display: none
		&_title
			display: none
			position: relative
			z-index: 11
			.selected &
				display: inline-block

		.icon
			color: currentColor
			font-size: 2.9rem
			position: absolute
			right: -3.7rem
			top: 50%
			+noselect
			+transform(translateY(-50%) rotate(0deg))
			+transition(color .3s ease, transform .3s ease)
	&_content
		background-color: transparent
		border: .3rem solid currentColor
		border-top: none
		display: block
		left: -.3rem
		list-style: none
		max-height: 0
		opacity: 0
		overflow: hidden
		padding: 0
		position: absolute
		top: 100%
		+transformorigin(top center)
		+transition(background-color .3s ease, border-color .3s ease, max-height .2s ease, opacity .3s ease, padding .3s ease, top .3s ease)
		width: calc(100% + .6rem)
		z-index: 10
		&_option
			background-color: transparent
			color: currentColor
			cursor: pointer
			display: block
			//margin: 1rem 0
			padding: 1.6rem 1.8rem
			position: relative
			+transition(background-color .3s ease)
			+noselect
			&:first-child
				margin-top: 1rem
			@media (hover:hover)
				&:hover
					background-color: $color-red
					color: $color-white
	&.open
		background-color: $color-white
		color: $color-black
		.select
			&_box
				.icon
					+transform(translateY(-50%) rotate(180deg))
			&_content
				background-color: $color-white
				max-height: 75vh
				opacity: 1
				overflow-y: auto
				//top: calc(100% - 1.8rem)
