.cards
	padding: 8rem 0
	.wrapper
		display: flex
		flex-direction: row
		flex-wrap: wrap
		justify-content: center
	h4.headline
		line-height: 1.38em
		margin-right: 1.5rem
	&_elem
		display: flex
		flex-direction: column
		text-align: center
		margin-right: 3rem
		outline: none
		width: calc(25% - 3rem)
		&:nth-child(4n)
			margin-right: 0
		&_link
			display: flex
			margin: 1.5rem 0 1.5rem 0
			.headline
				flex-grow: 1
				text-align: left
			.icon
				align-self: flex-end
				font-size: 2.5rem
				min-width: 4rem
				opacity: 0
				right: -1.5rem
				top: -.1em
				+transition(opacity .3s ease)
		&_img
			+backgroundcover()
			background-position: center center
			background-repeat: no-repeat
			display: block
			padding-top: 100%
			position: relative
			+transition(transform .3s ease)
			width: 100%
			img
				display: block
				height: 100%
				left: 0
				opacity: 0
				top: 0
				position: absolute
		@media(hover:hover)
			&:hover
				.cards_elem
					&_img
						+transform(scale(1.03))
					&_link
						.icon
							opacity: 1
				

	@media screen and (max-width: $mobilebp)
		.wrapper
			justify-content: flex-start
		&_elem
			margin-right: 1.25rem
			width: calc(50% - 1.25rem)
			&:nth-child(2n)
				margin-right: 0
			&:nth-child(4n)
				margin-right: 0
			&_link
				display: block
				padding-left: 0
				.headline
					width: 100%
				.icon
					display: none