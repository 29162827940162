.map
	display: block
	position: relative
	width: 100%
	svg
		height: auto
		width: 100%
	.lbbcareer_map_areas_area
		color: $color-red
		stroke: $color-white
		path
			stroke-width: .1rem
		&.hover
			color: $color-red-dark
		@media (hover:hover)
			&:hover
				color: $color-red-dark
				path
					stroke-width: .2rem
		&.active
			color: $color-white
	&.inverted
		.lbbcareer_map_areas_area
			color: $color-white
			stroke: $color-red
			&.active
				color: $color-red