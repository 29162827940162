.to-top-button
	bottom: 4rem
	display: inline-block
	left: calc(100% - 8rem)
	opacity: 0
	pointer-events: none
	position: sticky
	+transition(opacity .2s ease)
	.btn
		+transform(rotate(-90deg))
	&.show
		opacity: 1
		pointer-events: all
		z-index: 1000

	@media screen and (max-width: $mobilebp)
		left: calc(100% - 6.5rem)
		a.btn, button.btn
			&.no-text-btn
				width: 4.8rem
				height: 4.8rem