.error-container
	display: block
	line-height: 1.3em
	position: relative
	width: 100%
	text-align: center
	.headline
		color: $color-red
		margin-bottom: 2rem
	.icon
		color: $color-red
		font-size: 6rem
		margin: 0 auto 2rem auto