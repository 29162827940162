.search-filter
	display: block
	position: relative
	width: 100%
	&_name
		display: block
		font-size: 1.4rem
		+getComponent('font-md')
		line-height: 1.7em
		margin-bottom: 2rem
		position: relative
		text-align: left
		width: 100%
	&_tags
		display: flex
		flex-direction: row
		flex-wrap: wrap
		justify-content: flex-start
		list-style: none
		position: relative
		li
			display: block
			margin-bottom: 2rem
			margin-right: 2.4rem
			position: relative
			&:last-child
				margin-right: 0