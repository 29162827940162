.filter-tag
	background-color: $color-red
	color: $color-white
	cursor: pointer
	display: inline-block
	font-size: 1.6rem
	+getComponent('font-demi')
	line-height: 1.625em
	padding: .6rem 1.2rem .5rem 1.2rem
	position: relative
	+transform(scale(1))
	+transition(background-color .3s ease, transform .3s ease)
	width: auto
	&.active
		background-color: $color-grey-dark
	@media (hover:hover)
		&:hover
			+transform(scale(1.05))