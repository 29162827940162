.simple-offer-row
	align-items: center
	border-bottom: .2rem solid $color-grey-light
	display: flex
	flex-direction: row
	flex-wrap: nowrap
	+getComponent('font-demi')
	+hyphensauto
	padding: .5rem 0
	position: relative
	width: 100%
	&_title
		color: $color-red
		display: block
		font-size: 1.6rem
		line-height: 1.625em
		padding-right: 1.5rem
		position: relative
		width: 66%
	&_city
		color: $color-black
		display: block
		font-size: 1.4rem
		line-height: 1.7em
		padding-left: 1.5rem
		position: relative
		text-align: right
		width: 33%
		.missing
			color: $color-grey
	&.hide,
	&.filtered
		display: none