.imagetext-teaser
	background-color: $color-red
	display: flex
	flex-direction: row
	&_content
		color: $color-white
		display: flex
		flex-direction: column
		justify-content: center
		margin-left: auto
		max-width: #{$wrapper-maxwidth*.44}
		padding: 5rem 4rem 7rem 4rem
		width: 44%
		&_headline
			position: relative
			width: 100%
			.headline
				max-width: 100%
				overflow: hidden
				text-overflow: ellipsis
		&_button
			margin-top: 5rem
	&_img-container
		position: relative
		width: 56%
		&_img
			+backgroundcover
			background-position: center center
			background-repeat: no-repeat
			height: 100%
			object-fit: cover
			position: absolute
			width: 100%
		img
			height: 100%
			object-fit: cover
			opacity: 0
			width: 100%

	@media screen and (max-width: 1435px)
		h1.headline
			font-size: 6rem
	@media screen and (max-width: 1310px)
		h1.headline
			font-size: 4vw	
	@media screen and (max-width: 950px)
		flex-direction: column
		flex-flow: column-reverse
		max-height: 100%
		h1.headline
			font-size: 4rem
		&_content
			margin: 3.2rem 0
			max-width: 100%
			width: 100%
		&_img-container
			width: 100%
	@media screen and (max-width: $mobilebp)
		&_content
			padding: 2rem 1.7rem
			&_button
				text-align: right
