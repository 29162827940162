.offer-results
	display: block
	margin-top: 5rem
	position: relative
	&_filter
		display: block
		position: relative
		width: 100%
		.advanced-select-row
			margin: 0 auto
			.wrapper
				padding: 0
	&_sort
		display: flex
		flex-direction: row
		flex-wrap: nowrap
		justify-content: flex-end
		width: 100%
		.advanced-select
			width: calc(33% - 1.5rem)
			&_choosen
				display: none
	&_header
		display: block
		margin: 7rem auto 4rem auto
		position: relative
		&.hide
			display: none
	&_loader
		display: none
		margin: 0 auto
		margin-top: 7rem
		max-width: 79rem
		position: relative
		width: 65%
		.headline
			margin: 0 auto 2rem auto
			text-align: center
		&.show
			display: block
	&_list
		display: block
		position: relative
	&_load-more
		display: block
		margin-top: 4rem
		position: relative
		text-align: center
		&.hide
			display: none
		&_btn
			display: block
			margin-bottom: 1.5rem
			position: relative
		&_count
			.zero,
			.singular,
			.plural
				display: none
			&.zero
				.zero
					display: inline-block
				.count
					display: none
			&.singular
				.singular
					display: inline-block
			&.plural
				.plural
					display: inline-block
	&_pagination-control
		align-items: flex-start
		display: flex
		flex-direction: row
		flex-wrap: nowrap
		justify-content: center
		margin-top: 7rem
		position: relative
		width: 100%
		&.hide
			display: none
		&_prev,
		&_next
			&, &:link, &:visited, &:active
				border: .3rem dotted transparent
				color: $color-red
				cursor: pointer
				display: inline-block
				font-size: 1.6rem
				+getComponent('font-demi')
				outline: none
				padding: 1rem 2.4rem 1rem 0
				position: relative
				+transition(border-color .3s ease, color .3s ease, opacity .3s ease)
				span
					display: block
					position: absolute
					right: 0
					top: calc(50% - .05em)
					+transform(translateY(-50%))
					svg
						color: currentColor
						font-size: 1em
				&:focus
					border-color: currentColor
				@media (hover:hover)
					&:hover
						color: $color-black
			&.disabled
				opacity: .5
				pointer-events: none
		&_prev
			&, &:link, &:visited, &:active
				padding: 1rem 0 1rem 2.4rem
				span
					left: 0
					right: auto
					svg
						+transform(scale(-1,1))
		nav
			flex-grow: 1
			display: inline-block
			list-style: none
			margin: 0 1.5rem
			position: relative
			text-align: center
			li
				display: inline-block
				position: relative
				a
					&, &:link, &:visited, &:active
						border: .3rem dotted transparent
						color: $color-red
						cursor: pointer
						display: block
						font-size: 1.6rem
						+getComponent('font-demi')
						margin: 0 .5rem
						padding: 1rem
						outline: none
						position: relative
						+transition(border-color .3s ease, color .3s ease)
						&.active
							color: $color-black
							pointer-events: none
						&:focus
							border-color: currentColor
					@media (hover:hover)
						&:hover
							border-color: currentColor
							color: $color-black

	@media screen and (max-width: $mobilebp)
		margin-top: 1.5rem
		&_filter,
		&_sort
			margin: 0 1.5rem
			width: calc(100% - 3rem)
		&_sort
			margin-top: 2rem
			.advanced-select
				width: 100%
		&_header
			margin: 2rem auto 1.5rem auto
		&_list
			margin-left: -1.7rem
			width: calc(100% + 3.4rem)
		&_pagination-control
			flex-wrap: wrap
			&_prev,
			&_next
				margin: 0 1rem 1rem 1rem
				order: 1
			nav
				order: 2
				width: 100%