.job-profile-card
	color: $color-black
	display: block
	font-size: 1.6rem
	+perspective(1000px)
	position: relative
	+transition(transform .3s ease)
	width: 100%
	&_content
		align-content: flex-start
		+boxshadow(0 .2rem .9rem 0 $shadow_job-profile-card)
		cursor: pointer
		display: flex
		flex-direction: row
		flex-wrap: nowrap
		overflow-x: hidden
		position: relative
		+transformstyle(preserve-3d)
		+transition(transform .5s ease)
		width: 100%
	&_front,
	&_back
		display: flex
		flex-direction: column
		flex-grow: 1
		min-width: 100%
		position: relative
		+transition(order .4s ease, z-index .5s ease)
		width: 100%
		& > *
			+transition(opacity .5s ease)
		&::before
			background-color: $color-white
			content: ''
			display: block
			height: 100%
			left: 0
			position: absolute
			top: 0
			width: 100%
			z-index: -1
	&_front
		padding-top: 51%
		z-index: 1
		&_image
			+backgroundcover()
			background-position: top center
			background-repeat: no-repeat
			display: block
			left: 0
			padding-top: 51%
			position: absolute
			top: 0
			width: 100%
		&_title
			display: block
			padding: 1.6rem 2rem 2rem 2rem
			position: relative
			text-align: center
			width: 100%
			.headline
				+getComponent('font-demi')
	&_back
		padding: 1.6rem 2rem 1.5rem 2rem
		pointer-events: none
		+transform(rotateY(180deg))
		z-index: -1
		& > *
			opacity: 0
		&_title
			margin-bottom: 1.6rem
			.headline
				+getComponent('font-demi')
		&_text
			display: block
			flex-grow: 1
			+getComponent('font-reg')
			line-height: 1.625em
			position: relative
			width: 100%
		&_link
			display: block
			justify-self: flex-end
			margin-top: 1.6rem
			position: relative
			text-align: right
	&.flipped
		.job-profile-card
			&_content
				+transform(rotateY(180deg))
			&_front
				order: 2
				pointer-events: none
				z-index: -1
				& > *
					//opacity: 0
			&_back
				order: 1
				pointer-events: all
				z-index: 1
				& > *
					opacity: 1
	@media (hover:hover)
		&:hover
			+transform(scale(1.05))