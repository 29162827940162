.filter-bar
    display: block
    padding-bottom: 2.5rem
    position: relative
    width: 100%
    ul
        display: flex
        position: relative
        width: 100%
    &__filter
        cursor: pointer
        +getComponent('font-demi')
        display: block
        flex: 1 1 0px
        font-size: 1.8rem
        padding-right: 4rem
        position: relative
        &::after
            background-color: $color-grey
            bottom: -.6rem
            content: ''
            display: block
            height: .4rem
            left: 0
            position: absolute
            +transition(background-color .3s ease)
            width: 100%
        @media (hover:hover)
            &:hover
                &::after
                    background-color: $color-red-dark
        &.filter-bar__filter--active
            pointer-events: none
            &::after
                background-color: $color-red
    .simplebar
        &-scrollbar
            &::before
                opacity: .1!important
                +transition(opacity .3s ease!important)
            @media (hover:hover)
                &:hover
                    &::before
                        opacity: .33!important
    
    @media screen and (max-width: $mobilebp)
        &__filter
            padding-left: 1rem
        