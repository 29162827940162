.copy-image
	align-items: center
	background-color: $color-grey-light
	display: flex
	flex-direction: row
	flex-wrap: nowrap
	position: relative
	width: 100%
	&_left,
	&_right
		display: block
		position: relative
		width: 50%
	&_left
		text-align: center
		img
			display: block
			height: auto
			min-height: 100%
			position: relative
			width: 100%
	&_right
		margin: 0 auto 0 0
		max-width: math.div($wrapper-maxwidth,2)
		padding: 6rem 4rem 5rem 6rem

	&.right
		.copy-image
			&_right
				margin: 0 0 0 auto
				order: 1
				padding: 6rem 6rem 5rem 4rem
			&_left
				order: 2

	@media screen and (max-width: $mobilebp)
		flex-wrap: wrap
		&_left,
		&_right
			width: 100%
		&, &.right
			.copy-image
				&_left
					margin: 0 auto
					order: 2
				&_right
					margin: 0 auto
					order: 1
					padding: 3rem 1.7rem 0 1.7rem
		&_headline,
		&_text
			margin-bottom: 4rem