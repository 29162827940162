.radio
	cursor: pointer
	display: inline-block
	+getComponent('font-demi')
	line-height: 1.5em
	padding-left: 4rem
	position: relative
	+noselect
	&_box
		background-color: $color-white
		border: .2rem solid currentColor
		+borderradius(50%)
		display: inline-block
		height: 2.4rem
		left: 0
		position: absolute
		top: 0
		width: 2.4rem
		&::after
			background-color: $color-red
			+borderradius(50%)
			content: ''
			display: block
			height: 0
			left: 50%
			opacity: 0
			position: absolute
			top: 50%
			+transform(translate(-50%,-50%))
			+transition(height .3s ease, opacity .3s ease, width .3s ease)
			width: 0			
	input[type="radio"]
		height: 0
		opacity: 0
		position: absolute
		width: 0
		&:checked
			& ~ .radio_box
				border-color: currentColor
				&:after
					height: 66%
					opacity: 1
					width: 66%

	@media (hover:hover)
		&:hover
			.radio_box
				&::after
					height: 40%
					opacity: 1
					width: 40%