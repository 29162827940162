.features-image-slider
	background: $color-grey-light
	display: block
	padding: 8rem 0
	position: relative
	width: 100%
	&_container
		background: $color-grey-light
		display: flex
		padding-bottom: 8rem
		width: 100%
		&_left
			margin-left: auto
			max-width: #{$wrapper-maxwidth*.439}
			padding-left: 4rem
			padding-right: 4rem
			position: relative
			width: 43.9%
			&_headline
				margin-bottom: 7rem
			&_links
				margin-left: 10rem
				&_elem
					cursor: pointer
					display: flex
					margin-bottom: 3rem
					.icon
						height: 3rem
						min-width: 0
						opacity: 0
						top: -.1em
						+transition(min-width .3s ease, opacity .5s ease, width .3s ease)
						width: 0
					@media (hover:hover)
						&:hover
							.icon
								min-width: 3rem
								opacity: 1
								width: 3rem
					&.active
						pointer-events: none
						.icon
							min-width: 3rem
							opacity: 1
							width: 3rem
				h5.headline
					line-height: 1.8em
				li
					list-style-type: none
				&_button
					display: inline-block
		&_right
			display: block
			position: relative
			width: 56.1%
			&_img
				height: auto
				position: relative
				&_bg
					+backgroundcover
					background-position: center center
					background-repeat: no-repeat
					border-top: .5rem solid $color-red
					display: block
					max-height: 999rem
					overflow: hidden
					position: relative
					+transition(background-image .1s ease, max-height .3s ease)
					&::before,
					&::after
						+gradient(vertical, $color-red, $color-white, $color-red 50%, $color-white 50%)
						content: ''
						display: block
						height: 100%
						left: 0
						position: absolute
						top: 0
						width: .5rem
						z-index: 1
					&::after
						left: auto
						right: 0
					.image-copyright
						align-items: flex-start
						bottom: auto
						top: 1rem
				&_buttons-mobile
					display: none
				img
					opacity: 0
					width: 100%
				.new
					display: block
					left: 0
					position: absolute
					top: 0
			&_content
				bottom: 0
				display: flex
				flex-direction: row
				flex-wrap: nowrap
				left: -4rem
				line-height: 1.625em
				margin-top: -12rem
				min-width: 40rem
				position: relative
				width: 80%
				z-index: 666
				&_txtbox
					background: $color-white
					display: block
					flex-grow: 1
					+hyphensauto
					margin-right: 1rem
					overflow: hidden
					padding: 4rem
					position: relative
					+transition(max-height .3s ease)
					.new
						background-color: $color-white
						display: block
						+hyphensauto
						left: 0
						opacity: 0
						padding: 4rem
						position: absolute
						top: 0
						+transition(opacity .3s ease)
						width: 100%
						&.added
							height: 100%
							opacity: 1
							overflow: hidden
				&_buttons
					display: flex
					flex-direction: column
					margin-top: .5rem
					&.hide
						display: none
					&_left
						+transform(rotate(180deg))
						text-align: right
			&_button-mobile
				display: none
	&_content-block
		display: none

	@media screen and (max-width: $tabletbp)
		padding: 0
		&_container
			flex-direction: column
			padding-bottom: 0
			&_left
				max-width: 100%
				padding: 3.5rem 1.7rem 0 1.7rem
				width: 100%
				&_headline
					margin-bottom: 2rem
				&_links
					margin-left: 0
					-webkit-overflow-scrolling: touch
					display: block
					overflow: auto
					white-space: nowrap
					&_elem
						color: $color-grey
						&.active
							color: $color-black
					&_button
						display: none
					li
						display: inline-flex
						margin-right: 3rem
			&_right
				width: 100%
				&_img
					margin-left: 0
				&_content
					display: block
					left: 0
					margin: 0
					min-width: inherit
					width: 100%
					z-index: 0
					&_txtbox
						margin-right: 0
						max-width: 100%
						padding: 3.5rem 5rem 3.5rem 2rem
					.new
						padding: 3.5rem 5rem 3.5rem 2rem
					&_buttons
						position: absolute
						right: 1.7rem
						top: -60%
				&_button-mobile
					background-color: $color-white
					display: block 
					padding: 0 2rem	2rem 2rem
	@media screen and (max-width: $mobilebp)
		&_container
			&_right
				&_content
					&_buttons
						top: -10rem
