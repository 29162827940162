.contact-container
	.wrapper
		display: flex
	&_left
		width: 60%
		&_headline
			padding: 0 7rem 8rem 0
			span
				color: $color-grey
				font-size: 1.8rem
		&_contact-form
			background-color: $color-grey-light
			margin-right: -3.5rem
			padding: 3.5rem
			position: relative
			z-index: 666
			&_row
				position: relative
				span
					opacity: 0
					position: absolute
					right: 2%
					top: 23.5%
				.textarea-icon
					top: 6%
				label
					position: absolute
					top: 1.5rem
					left: 2rem
					pointer-events: none
					@media (hover:hover)
						&:hover
							cursor: auto
				textarea
					border: .3rem solid transparent
					min-height: 20rem
					&.error
						border-color: $color-red
						color: $color-red
				input[type="text"],input[type="email"], textarea
					border: .3rem solid $color-grey-light
					margin-bottom: 1.5rem
					padding: 1.2rem
					width: 100%
					&.error
						border-color: $color-red
						color: $color-red
					&:focus
						border: .3rem solid $color-black
						outline: none
						& + label
							opacity: 0
							+transition(opacity .5s ease)
					&:not(:focus)
						&:not(:placeholder-shown)
							&:valid
								border: .3rem solid $color-black
								color: $color-black
								& + label
									opacity: 0
									+transition(opacity .5s ease)
							&:invalid
								border: .3rem solid $color-red
								color: $color-red
								& + label
									opacity: 0
									+transition(opacity .5s ease)
			&_submit
				border: .2rem solid transparent
				display: flex
				justify-content: space-between
				&_checkbox
					position: relative
					margin-right: 2rem
				&_error-msg
					background-color: $color-red
					color: $color-white
					display: block
					+getComponent('font-reg')
					font-weight: bold
					left: 2%
					opacity: 0
					padding: 1.3rem
					position: absolute
					top: 8.5rem
					+transition(opacity .3s ease)
					width: fit-content
					&::before
						content: ''
						border-style: solid
						border-width: 1rem 0 0 1rem
						border-color: transparent transparent transparent $color-red
						height: 0
						position: absolute
						top: -1rem
						left: 0
						width: 0
					&.show
						opacity: 1
			&_error-msg
				background-color: $color-red
				color: $color-white
				display: block
				+getComponent('font-reg')
				font-weight: bold
				left: 102%
				margin-top: .3rem
				opacity: 0
				padding: 1.2rem
				pointer-events: none
				position: absolute
				top: 0
				+transition(opacity .3s ease)
				width: fit-content
				&::before
					content: ''
					border-style: solid
					border-width: 1rem 1rem 1rem 0
					border-color: transparent $color-red transparent transparent
					height: 0
					position: absolute
					top: 1.2rem
					left: -.8rem
					width: 0
				&.show
					opacity: 1
			&_input-preventer
				background-color: $color-white
				display: block
				height: 100%
				left: 0
				opacity: 0
				pointer-events: none
				position: absolute
				top: 0
				+transition(opacity .3s ease)
				width: 100%
				z-index: 666
				&.shown
					opacity: .5
					pointer-events: all
			&_overlay
				align-items: center
				background-color: $color-grey-light
				color: $color-black
				display: none
				flex-direction: row
				flex-wrap: nowrap
				height: 100%
				justify-content: center
				margin-left: -3.5rem
				margin-right: 0
				opacity: 0
				padding: 2.5rem 3.5rem
				position: absolute
				text-align: center
				top: 0
				+transition(opacity .3s ease)
				width: 100%
				z-index: 667
				&.shown
					display: flex
					opacity: 1
	&_right
		display: flex
		flex-direction: column
		width: 40%
		&_info
			margin: 5rem 1.7rem 0 9rem
			.icon
				font-size: 3rem
			&_headline
				margin-bottom: 5rem
				h6.headline
					+getComponent('font-demi')	
			&_address
				&_elem
					display: block
					line-height: 1.3em
					margin-bottom: 2.5rem
					overflow-wrap: break-word
					padding-left: 5rem
					padding-top: .2rem
					position: relative
					a
						&, &:link, &:visited, &:active
							color: inherit
							+transition(color .3s ease)
						@media (hover:hover)
							&:hover
								color: $color-red
					.icon
						left: 0
						position: absolute
						top: -.1em
			&_socials
				a
					&, &:link, &:visited, &:active
						display: inline-block
						margin: 0 1rem
						position: relative
						+transition(transform .3s ease)
						&:first-child
							margin-left: 0
					.icon
						color: $color-red
					@media (hover:hover)
						&:hover
							+transform(scale(1.1))
		&_img
			+backgroundcover
			background-position: center center
			background-repeat: no-repeat
			max-height: 50%
			min-height: 39.2rem
			object-fit: cover
			position: relative
			img
				opacity: 0
			.image-copyright
				align-items: flex-start
				bottom: auto
				top: 1rem
		&_headline-mobile
			display: none
			span
				color: $color-grey
				font-size: 1.8rem

	@media (min-width: 851px) and (max-width: 1080px)
		&_left
			&_contact-form	
				&_error-msg
					max-width: 30rem
	@media (min-width: 851px) and (max-width: $tabletbp)
		&_right
			&_info
				margin: 3.5rem 1.7rem 0 7.5rem
	@media screen and (max-width: 850px)
		.wrapper
			flex-direction: column-reverse
		&_left
			width: 100%
			&_headline
				display: none
			&_contact-form
				padding: 2.5rem 3.5rem 3.5rem 3.5rem
				margin: 0 -4rem
				z-index: 0
				&_submit
					display: block
					&_checkbox
						margin-bottom: 3rem
					&_button
						text-align: right
					&_error-msg
						margin: 0 .6rem 0 0
						top: 5.7rem
						z-index: 1
				&_error-msg
					left: auto
					max-width: 80%
					right: 4rem
					top: 4.3rem
					z-index: 1
					&::before
						border-width: 0 0 1rem 1rem
						border-color: transparent transparent $color-red transparent
						top: -1rem
						+transform(scale(2.1,4) rotate(21deg) translate(-.15rem,0))
						left: calc(100% - 1rem)
				&_overlay
					width: 100%
		&_right
			max-height: 100%
			width: 100%
			&_info
				padding: 3.5rem 0
				margin: 0
				width: 100%
				&_headline
					margin-bottom: 3rem
				&_address
					&_elem
						margin-bottom: 2rem
			&_img
				display: none
			&_headline-mobile
				display: block
	@media screen and (max-width: $mobilebp)
		&_left
			&_contact-form
				margin: 0 -1.7rem
				&_submit
					&_error-msg
						width: calc(100% + 1.4rem)