.image-bullet-list
	background: $color-grey-light
	display: block
	padding: 6rem 0 7rem 0
	position: relative
	width: 100%
	&_headline
		display: block
		margin-bottom: 6rem
		margin-left: auto
		margin-right: 30%
		max-width: #{$wrapper-maxwidth*.7}
		position: relative
		width: 100%
	&_content
		display: flex
		flex-direction: row
		flex-wrap: nowrap
		position: relative
		&_bullets
			display: flex
			flex-wrap: wrap
			max-width: #{$wrapper-maxwidth*.7}
			padding-left: 3rem
			width: 100%
			&_elem
				display: block
				margin-bottom: 2rem
				position: relative
				width: calc(50% - 1rem)
				&:nth-child(odd)
					margin-right: 1rem
				&:nth-child(even)
					margin-left: 1rem
				&_headline
					display: flex
					flex-direction: row
					flex-wrap: nowrap
					margin-bottom: 2.5rem
					.icon
						display: block
						font-size: 3rem
						margin-right: 1rem
				&_text
					display: block
					line-height: 1.625em
					padding-left: 4rem
					position: relative
		&_button
			text-align: right
			margin-right: 8rem	
		&_image
			display: block
			position: relative
			width: 30%
			img
				display: block
				height: auto
				position: relative
				width: 100%
			.image-copyright
				align-items: flex-start
				bottom: auto
				top: 1rem
		&.right
			flex-direction: row-reverse
			.image-bullet-list_content
				&_bullets
					padding-left: 0
					padding-right: 3rem

			
	@media screen and (max-width: $mobilebp)
		padding: 3rem 0 4rem 0
		&_headline
			max-width: inherit
			margin: 0 auto 3.5rem auto
			padding: 0 1.7rem
		&_content
			flex-wrap: wrap
			&_image,
				&, img
					width: 100%
				
			&_bullets
				margin: 3rem 0 0 0
				max-width: inherit
				padding: 0 1.7rem	
				&_elem
					margin-bottom: 3.5rem
					width: 100%
					&:nth-child(odd),
					&:nth-child(even)
						margin-left: auto
						margin-right: auto
					&:last-child
						margin-bottom: 0
					&_headline
						margin-bottom: 1rem
					&_text
						padding-left: 0
					
			&_button
				margin-right: 0

			&.right
				.image-bullet-list_content
					&_bullets
						padding: 0 1.7rem
