@keyframes scroll-down-hint-move
	0%
		color: $color-white
		+transform(translate(-50%, 0))
	50%
		color: $color-red
		+transform(translate(-50%, 1rem))
	100%
		color: $color-white
		+transform(translate(-50%, 0))

@keyframes scroll-down-hint-move-integrated
	0%
		color: $color-white
		+transform(translateY(0))
	50%
		color: $color-red
		+transform(translateY(1rem))
	100%
		color: $color-white
		+transform(translateY(0))

.scroll-down-hint
	bottom: 2rem
	color: $color-white
	cursor: pointer
	display: block
	left: 50%
	position: fixed
	+transform(translateX(-50%))
	+noselect
	z-index: $layer_scroll-down-hint
	.icon
		font-size: 3rem
		+transform(rotate(90deg))
	&.animated
		+animation(scroll-down-hint-move, 1.5s, infinite)
		color: $color-red
	&.integrated
		display: table
		margin: 2rem auto
		left: auto
		position: relative
		top: auto
		+transform(none)
		z-index: auto
		&.animated
			+animation(scroll-down-hint-move-integrated, 1.5s, infinite)
	
	@media screen and (max-width: $mobilebp)
		display: none