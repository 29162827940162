.image-icon-bullet-list
	align-items: flex-start
	display: flex
	flex-direction: row
	flex-wrap: nowrap
	position: relative
	width: 100%
	&_image
		+backgroundcover
		background-repeat: no-repeat
		background-position: top center
		display: block
		position: relative
		width: 30%
		img
			display: block
			height: auto
			opacity: 0
			position: relative
			width: 100%
	&_content
		display: block
		margin-right: auto
		max-width: #{$wrapper-maxwidth*.7}
		padding: 0 5rem
		position: relative
		width: 100%
		&_headline
			display: block
			margin-bottom: 10rem
			max-width: 64rem
			padding: 0 5rem 0 0
			position: relative
			width: 100%
			&-mobile
				display: none
				margin: 0 0 3rem 0
				max-width: inherit
				padding: 0 1.7rem
				position: relative
				text-align: center
		&_list
			display: flex
			flex-direction: row
			flex-wrap: wrap
			margin: -.7rem -4rem
			position: relative
			width: calc(100% + 8rem)
			.icon-bulletpoint
				margin: .7rem 4rem
				width: calc(50% - 8rem)

	&.right
		.image-icon-bullet-list
			&_image
				order: 2
			&_content
				order: 1
				margin-left: auto
				margin-right: inherit
	@media screen and (max-width: $mobilebp)
		&.left,
		&.right
			flex-wrap: wrap
			.image-icon-bullet-list
				&_image
					max-height: 100vw
					order: 1
					overflow: hidden
					width: 100%
				&_content
					order: 2
					padding: 0 1.7rem
					width: 100%
					&_headline
						display: none
						&-mobile
							display: block
					&_list
						margin: 0
						padding-top: 2rem
						width: 100%
						.icon-bulletpoint
							margin: 2rem 0
							width: 100%