.features-video-slider
	display: block
	position: relative
	width: 100%
	&_header
		margin-bottom: 4.5rem
	&_container
		&_video
			border-top: .5rem solid $color-red
			display: block
			max-height: 999rem
			overflow: hidden
			position: relative
			+transition(max-height .3s ease)
			width: 60%
			&::before,
			&::after
				+gradient(vertical, $color-red, $color-white, $color-red 50%, $color-white 50%)
				content: ''
				display: block
				height: 100%
				left: 0
				position: absolute
				top: 0
				width: .5rem
				z-index: 12
			&::after
				left: auto
				right: 0
			&_play-button
				bottom: 0
				left: .5rem
				position: absolute
				+transition(opacity .3s ease)
				z-index: 12
			video
				display: block
				left: 0
				position: relative
				top: 0
				width: 100%
			video.new,
			.new
				display: block
				left: 0
				opacity: 0
				position: absolute
				top: 0
				+transition(opacity .3s ease)
				width: 100%
			.new
				height: 100%
				z-index: 10
			video.new
				height: auto
				z-index: 9
			&.added
				video.new,
				.new
					opacity: 1
			&_loaderimg
				+backgroundcover()
				background-repeat: no-repeat
				background-position: center center
				display: block
				height: 100%
				left: 50%
				pointer-events: none
				position: absolute
				+transform(translate(-50%,-50%))
				+transition(opacity .3s ease)
				top: 50%
				width: 100%
				z-index: 1
				img
					display: block
					opacity: 0
					position: absolute
		&_block
			background-color: $color-white
			display: flex
			margin: -10rem 0 0 35%
			max-width: 63.7rem
			position: relative
			+transition(background-color .3s ease)
			z-index: 666
			&_content
				display: block
				+hyphensauto
				max-height: 999rem
				opacity: 1
				overflow-x: hidden
				padding: 5rem 1.3rem 0 5rem
				position: relative
				+transition(max-height .3s ease, opacity .3s ease)
				&_headline
					margin-bottom: 4rem
				&_subline
					margin-bottom: 3rem
				&_text
					margin-bottom: 3.5rem
				&_links
					display: flex
					&_button
						margin-right: 3rem
						+transition(opacity .3s ease)
					&_link-container
						display: flex
						align-items: center
				.new
					background-color: $color-white
					display: block
					left: 0
					opacity: 0
					padding: 5rem 1.3rem 0 5rem
					position: absolute
					top: 0
					+transition(opacity .3s ease)
					z-index: 1
					&.added
						height: 100%
						opacity: 1
						overflow: hidden
			&_buttons
				display: flex
				flex-direction: column
				margin-top: .5rem
				z-index: 10
				&.hide
					display: none
				&_left
					+transform(rotate(180deg))
					text-align: right
	&_content-block
		display: none

	.video-button
		background-color: $color-red
		border: none
		cursor: pointer
		height: 9rem
		padding: 0
		width: 9rem
		+transition(background-color .3s ease)
		.icon
			color: $color-white
			font-size: 5rem
			+transition(color .3s ease)
		@media (hover:hover)
			&:hover
				background-color: $color-white
				.icon
					color: $color-red

	&.playing
		.features-video-slider_container_video_play-button
			opacity: 0
			pointer-events: none
		.features-video-slider_container_video_loaderimg
			opacity: 0
			pointer-events: none
		.features-video-slider_container_block
			background-color: transparent
			pointer-events: none
			&_content
				opacity: 0
				pointer-events: none

	@media screen and (max-width: $tabletbp)
		&_header
			margin-bottom: 3rem
		&_container
			&_video
				margin: 0 -4rem
				width: calc(100% + 8rem)
			&_block
				display: flex
				justify-content: space-between
				margin: 0
				&_buttons
					margin-top: 12.8rem
				&_content
					&_links
						justify-content: space-between
				max-width: 100%
				&_content
					padding: 5rem 1.7rem 0 0
				z-index: 0
	@media screen and (max-width: $mobilebp)
		&_container
			&_video
				margin: 0 -1.7rem
				width: calc(100% + 3.4rem)