.cct-answer-range
    display: block
    position: relative
    &_terms
        display: flex
        flex-direction: row
        flex-wrap: nowrap
        margin-bottom: 2rem
        position: relative
        width: 100%
        &_left,
        &_right
            max-width: 50%
            text-align: center
        &_right
            margin-left: auto
    &_steps
        display: flex
        justify-content: space-between
        left: 5%
        list-style: none
        position: absolute
        top: 0
        width: 90%
        li
            background-color: $color-white
            +borderradius(50%)
            height: 1.4rem
            text-indent: -9999rem
            top: 0
            pointer-events: none
            position: relative
            width: 1.4rem
    &_slider
        display: block
        margin: 0 auto
        position: relative
        width: 100%
        .range
            left: calc(5% - 1.85rem)
            width: calc(90% + 3.7rem)
            &_bgrd
                left: 1.85rem
                width: calc(100% - 3.7rem)
    &_answers
        display: flex
        flex-direction: row
        flex-wrap: no-wrap
        justify-content: space-between
        list-style: none
        margin: 1.5rem -2.5% 7.5rem -2.5%
        width: 105%
        li
            flex-basis: 0
            flex-grow: 1
            display: block
            +getComponent('font-demi')
            font-size: 1.8rem
            padding: 1.5rem 2rem
            position: relative
            text-align: center
            &:first-child
                left: 2.5%
                padding-left: 0
                text-align: left
            &:last-child
                padding-left: 2.5%
                padding-right: 0
                right: 2.5%
                text-align: right
    &_answer-mobile
        display: none
        +getComponent('font-demi')
        font-size: 1.8rem
        margin: 5rem auto 0 auto
        text-align: center
        width: 50%

    @media screen and (max-width: $mobilebp)
        &_answers
            display: none
        &_answer-mobile
            display: block