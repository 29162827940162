.application-period
	display: flex
	flex-direction: row
	flex-wrap: nowrap
	position: relative
	width: 100%
	&_left
		background-color: $color-grey-light
		color: $color-black
		display: block
		flex-grow: 1
		order: 1
		padding: 5rem 0 7rem 4rem
		position: relative
		width: 64%
		&_content
			display: flex
			flex-direction: column
			height: 100%
			justify-content: center
			margin-left: auto
			max-width: #{$wrapper-maxwidth*.64}
			padding-right: 10rem
			position: relative
			.right &
				margin-left: 0
				margin-right: auto
				padding-left: 10rem
				padding-right: 0
			&_headline
				.headline
					font-size: 7rem
			&_text
				margin: 3rem 0 4rem 0
				.headline
					+getComponent('font-demi')
		.right &
			order: 2
			padding-left: 0
			padding-right: 4rem
					
	&_right
		background-color: $color-red
		flex-grow: 0 1
		display: flex
		flex-direction: column
		justify-content: center
		order: 2
		padding: 14rem 0 16rem 0
		position: relative
		text-align: center
		width: 36%
		&_icon
			color: $color-white
			.icon
				font-size: 22.3rem
		.right &
			order: 1
			padding-left: 4rem
			padding-right: 0
	
	@media screen and (max-width: $tabletbp)
		flex-wrap: wrap
		&_left,
		&_right
			.right &,
			&
				display: block
				padding: 5rem 4rem
				width: 100%
		&_left
			&_content
				.right &,
				&
					margin: 0
					max-width: inherit
				&_headline
					.headline
						font-size: 4rem
				&_link
					text-align: right
				.right &,
				&
					padding: 0
			.right &,
			&
				order: 2
		&_right
			&_icon
				.icon
					font-size: 14rem
			.right &,
			&
				order: 1
	@media screen and (max-width: $mobilebp)
		&_left
			.right &,
			&
				padding: 3rem 1.7rem
			&_content
				&_headline
					.headline
						font-size: 3rem
				&_text
					margin: 2rem 0 3rem 0
		&_right
			.right &,
			&
				padding: 5rem 1.7rem
			&_icon
				.icon
					font-size: 10rem