.checkbox
	cursor: pointer
	display: inline-block
	+getComponent('font-demi')
	line-height: 1.5em
	padding-left: 4rem
	position: relative
	+noselect
	&_box
		background-color: $color-white
		border: .2rem solid currentColor
		display: inline-block
		height: 2.4rem
		left: 0
		position: absolute
		top: 0
		width: 2.4rem
		&_checkmark
			display: block
			height: 100%
			left: 0
			opacity: 0
			position: absolute
			top: 0
			+transform(scale(0))
			+transition(opacity .3s ease, transform .3s ease)
			width: 100%
			&::before,
			&::after
				background-color: $color-red
				content: ''
				display: block
				height: .4rem
				left: 50%
				position: absolute
				+transform(translate(calc(-50% + .5rem),-50%) rotate(-45deg))
				+transition(height .1s ease, transform .3s ease, width .1s ease)
				top: 50%
				width: 0
			&::before
				height: calc(100% - .8rem)
				+transform(translate(-50%,-50%))
				width: calc(100% - .8rem)
		&_span-text
			display: inline-block
			+getComponent('font-reg')
			margin-left: .3em
		.error &,
		&.error
			border-color: $color-red
	input[type="checkbox"]
		height: 0
		opacity: 0
		position: absolute
		width: 0
		&:checked
			& ~ .checkbox_box
				border-color: $color-black
				.checkbox_box_checkmark
					opacity: 1
					+transform(scale(1))
					&::before
						height: .4rem
						+transform(translate(calc(-50% - .7rem),calc(-50% + .5rem)) rotate(45deg))
						width: 1.3rem
					&::after
						opacity: 1
						width: 2.6rem
				
	@media (hover:hover)
		&:hover
			.checkbox_box
				&_checkmark
					opacity: 1
					+transform(scale(1))
					&::after
						opacity: 0