.cct-check-radio
	cursor: pointer
	position: relative
	input
		&[type="radio"],
		&[type="checkbox"]
			height: 0
			opacity: 0
			position: absolute
			width: 0
	input[type="radio"] ~ &_box
		background-color: $color-white
		border: .3rem solid $color-grey
		+borderradius(50%)
		display: block
		height: 2.6rem
		position: relative
		+transition(border-color .3s ease)
		width: 2.6rem
		&::before
			background-color: $color-red
			+borderradius(50%)
			content: ''
			display: block
			height: 0
			left: 50%
			opacity: 0
			position: absolute
			top: 50%
			+transform(translate(-50%,-50%))
			+transition(height .3s ease, opacity .3s ease, width .3s ease)
			width: 0
	input[type="radio"]:checked ~ &_box
		border-color: $color-black
		&::before
			height: 70%
			opacity: 1
			width: 70%
	input[type="checkbox"] ~ &_box
		background-color: $color-white
		border: .2rem solid $color-grey
		display: block
		height: 2.6rem
		position: relative
		width: 2.6rem
		&::before,
		&::after
			background-color: $color-red
			content: ''
			display: block
			height: 50%
			left: 50%
			opacity: 0
			position: absolute
			top: 50%
			+transform(translate(-50%,-50%) scale(0))
			+transition(heigth .3s ease, opacity .3s ease, transform .3s ease, width .3s ease)
			width: 50%
		&::after
			height: .4rem
			width: 125%
	input[type="checkbox"]:checked ~ &_box
		border-color: $color-black
		&::before
			height: .4rem
			opacity: 1
			+transform(translate(-100%,40%) rotate(40deg) scale(1))
			width: 70%
		&::after
			opacity: 1
			+transform(translate(-35%,-105%) rotate(-50deg) scale(1))
	
	.image-tile &,
	.faded-box &
		.cct-check-radio_box
			&::before,
			&::after
				background-color: $color-white
		input[type="checkbox"] ~ .cct-check-radio_box,
		input[type="radio"] ~ .cct-check-radio_box
			background-color: $color-white-a30
			border: .2rem solid $color-white
		input[type="checkbox"]:checked ~ .cct-check-radio_box
			border-color: $color-white
	
	@media (hover:hover)
		label:hover &,
		&:hover
			input[type="radio"]
				& ~ .cct-check-radio_box
					&::before
						height: 33%
						opacity: 1
						width: 36%
				&:checked ~ .cct-check-radio_box
					&::before
						height: 70%
						opacity: 1
						width: 70%
			input[type="checkbox"]
				& ~ .cct-check-radio_box
					&::before
						opacity: 1
						+transform(translate(-50%,-50%) scale(1))
				&:checked ~ .cct-check-radio_box
					&::before
						height: .4rem
						opacity: 1
						+transform(translate(-100%,40%) rotate(40deg) scale(1))
						width: 70%
					&::after
						opacity: 1
						+transform(translate(-35%,-105%) rotate(-50deg) scale(1))