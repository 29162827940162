a.breaker,
.breaker
    align-items: center
    aspect-ratio: 1 / 1
    background-color: $color-red
    +borderradius(50%)
    +boxshadow(0 .5rem .5rem 0 rgba(15,15,15,.33))
    color: $color-white
    display: inline-flex
    font-size: 1.6rem
    +getComponent('font-demi')
    justify-content: center
    line-height: 1.3em
    padding: 2.5rem
    position: relative
    text-align: center
    +transform(rotate(10deg))
    +transition(transform .3s ease)
    width: 15em
    z-index: 10

    &--link
        cursor: pointer
        +noselect
        @media (hover:hover)
            &:hover
                +transform(scale(1.1) rotate(10deg))

    @media screen and (max-width: $mobilebp)
        font-size: 1.2rem
        padding: 1.5rem 2.5rem